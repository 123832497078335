<template>
  <v-container
    v-if="viewData"
    fluid
  >
    <general-stats
      v-if="userCan('Reviews', 'ACCESS') || userCan('Offers', 'ACCESS') || userCan('Orders', 'ACCESS')"
      :data="viewData"
    />

    <v-row v-if="userCan('Offers', 'ADD') || userCan('Orders', 'ADD')">
      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          block
          x-large
          :color="$t('Route.Offers.Color')"
          @click="onNewOffer"
        >
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          {{ $t('Offers.Button.New') }}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          block
          x-large
          :color="$t('Route.Orders.Color')"
          @click="onNewOrder"
        >
          <v-icon class="mr-1">
            mdi-plus
          </v-icon>
          {{ $t('Orders.Button.New') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="userCan('Reviews', 'ACCESS')">
      <v-col cols="12">
        <review-chart
          :data="viewData.Reviews.Chart.Data"
          :options="viewData.Reviews.Chart.Options"
          :height="300"
        />
      </v-col>
    </v-row>

    <v-row v-if="userCan('Offers', 'ACCESS') || userCan('Orders', 'ACCESS')">
      <v-col
        v-if="userCan('Offers', 'ACCESS')"
        cols="12"
        :md="userCan('Orders', 'ACCESS') ? '6' : '12'"
      >
        <latest-offers :data="viewData.Offers" />
      </v-col>

      <v-col
        v-if="userCan('Orders', 'ACCESS')"
        cols="12"
        :md="userCan('Offers', 'ACCESS') ? '6' : '12'"
      >
        <latest-orders :data="viewData.Orders" />
      </v-col>
    </v-row>

    <offer-edit-dialog
      v-if="userCan('Offers', 'ADD') || userCan('Offers', 'MANAGE')"
      :visible.sync="offerEditDialogVisible"
      @saveSuccess="getData"
    />

    <order-edit-dialog
      v-if="userCan('Orders', 'ADD') || userCan('Orders', 'MANAGE')"
      :visible.sync="orderEditDialogVisible"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth              from '../../mixins/auth'
import Dashboard         from '../../api/mixins/Dashboard'
import AutoUpdateApiData from '../../mixins/autoUpdateApiData'
import GeneralStats      from '@/views/dashboard/components/GeneralStats'
import LatestOffers      from '@/views/dashboard/components/LatestOffers'
import LatestOrders      from '@/views/dashboard/components/LatestOrders'
import ReviewChart       from '@/views/dashboard/components/ReviewChart'
import OfferEditDialog   from '@/views/offers/OfferEditDialog'
import OrderEditDialog   from '@/views/orders/OrderEditDialog'

export default {
  name      : 'DashboardDealer',
  components: {
    ReviewChart,
    GeneralStats,
    LatestOffers,
    LatestOrders,
    OfferEditDialog,
    OrderEditDialog
  },
  directives: {},
  mixins    : [Auth, Dashboard, AutoUpdateApiData],
  props     : [],
  data () {
    return {
      offerEditDialogVisible: false,
      orderEditDialogVisible: false
    }
  },
  computed: {
    viewData () {
      return this.$DataStore.Dashboard
    }
  },
  watch   : {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onNewOffer () {
      this.offerEditDialogVisible = true
    },
    onNewOrder () {
      this.orderEditDialogVisible = true
    },

    getData () {
      if (this.userCan('Dashboard', 'ACCESS')) {
        this.get()
          .then(response => {
            if (response && response.status === 200 && response.data) {
              const Chart = {
                Data: {
                  labels  : [
                    this.$t('Reviews.Table.Headers.Q1'),
                    this.$t('Reviews.Table.Headers.Q2'),
                    this.$t('Reviews.Table.Headers.Q3'),
                    this.$t('Reviews.Table.Headers.Q4'),
                    this.$t('Reviews.Table.Headers.Q5'),
                    this.$t('Reviews.Table.Headers.Q6'),
                    this.$t('Reviews.Table.Headers.Q7')
                  ],
                  datasets: [
                    {
                      data           : [],
                      fill           : false,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 205, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(201, 203, 207, 0.2)'
                      ],
                      borderColor    : [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(201, 203, 207)'
                      ],
                      borderWidth    : 1
                    }
                  ]
                },

                Options: {
                  responsive         : true,
                  maintainAspectRatio: false,
                  scaleBeginAtZero   : true,
                  title              : {
                    display  : true,
                    fontSize : 18,
                    fontStyle: 'normal',
                    text     : 'Αποτελέσματα Αξιολογήσεων'
                  },
                  legend             : {
                    display: false
                  },
                  tooltips           : {
                    callbacks: {
                      title: (tooltipItem, data) => {
                        return `${(tooltipItem[0].index + 1)}. ${this.$t(`Reviews.Form.Fields.Q${tooltipItem[0].index + 1}.Question`)}`
                      },
                      label: (tooltipItem, data) => {
                        return 'Μέσος όρος απαντήσεων: ' + tooltipItem.value
                      }
                    }
                  },
                  scales             : {
                    xAxes: [
                      {
                        ticks: {
                          beginAtZero: true
                        }
                      }
                    ]
                  }
                }
              }
              Chart.Data.datasets[0].data = response.data?.Reviews?.ChartData || []
              response.data.Reviews.Chart = Chart
              this.$DataStore.Dashboard = response.data
            }
          })
          .catch(e => { })
          .finally(() => {})
      }
    }
  }
}
</script>

<style scoped>

</style>
