import Auth      from './auth'
import Common    from './common'
import Dashboard from './dashboard'
import Dealers   from './dealers'
import Users     from './users'
import Offers    from './offers'
import Orders    from './orders'
import Reviews   from './reviews'
import Settings  from './settings'
import Route     from './route'

export default {
  Auth     : Auth,
  Common   : Common,
  Dashboard: Dashboard,
  Dealers  : Dealers,
  Users    : Users,
  Offers   : Offers,
  Orders   : Orders,
  Reviews  : Reviews,
  Settings : Settings,
  Route    : Route
}
