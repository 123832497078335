<template>
  <div class="pb-4">
    <list-card
      v-if="userCan('Offers', 'ACCESS')"
      :data="data.Data"
      :title="$t('Route.Offers.Title')"
      :icon="$t('Route.Offers.Icon')"
      :colored-border="false"
      :color="$t('Route.Offers.Color')"
      :avatar-icon="$t('Route.Offers.Icon')"
      avatar-icon-color="black"
      avatar-color="grey lighten-2"
      list-height="300"
      dark
      dense
      avatar
      click-event="item-click"
      @item-click="onItemClick"
    >
      <template #toolbar-append>
        <v-tooltip
          :open-delay="650"
          top
        >
          <template #activator="{on}">
            <v-btn
              icon
              :to="{name: 'Offers'}"
              v-on="on"
            >
              <v-icon>mdi-table-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Common.Button.ViewAll') }}</span>
        </v-tooltip>
      </template>

      <template #list-item-content-prepend="{}">
        <v-list-item-avatar
          :color="$t('Route.Offers.Color')"
          class="align-center justify-center"
        >
          <v-icon dark>
            {{ $t('Route.Offers.Icon') }}
          </v-icon>
        </v-list-item-avatar>
      </template>

      <template #list-item-content="{item, color}">
        <v-list-item-content>
          <v-list-item-title
            :class="`${color}--text`"
            v-text="`${item.Firstname} ${item.Lastname}`"
          />

          <v-list-item-subtitle class="text-no-wrap text--primary">
            {{ item.Car }}
            <template v-if="item.Car && item.Plate">
              -
            </template>
            {{ item.Plate && item.Plate.toLocaleUpperCase($i18n.locale) }}
            <template v-if="(item.Car || item.Plate) && item.Mileage">
              -
            </template>
            <span v-if="item.Mileage" class="grey--text">{{ item.Mileage }} xλμ.</span>
          </v-list-item-subtitle>

          <v-list-item-subtitle class="text-no-wrap caption">
            {{ item.Phone }}
            <template v-if="item.Phone && item.Email">
              -
            </template>
            {{ item.Email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template #list-item-content-append="{item}">
        <v-list-item-action class="align-center">
          <v-list-item-action-text v-text="item.OfferedAt" />
          <div>
            <v-btn
              icon
              large
              color="teal"
              @click.stop="downloadPDF(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </template>
    </list-card>

    <offer-details-dialog
      :item="selectedItem"
      :visible.sync="viewDialogVisible"
    />
  </div>
</template>

<script>
import Auth               from '@/mixins/auth'
import ListCard           from '@/components/widgets/ListCard'
import OfferDetailsDialog from '@/views/offers/OfferEditDialog'

export default {
  name      : 'LatestOffers',
  components: {
    OfferDetailsDialog,
    ListCard
  },
  directives: {},
  mixins    : [Auth],
  props     : {
    data: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      selectedItem     : null,
      viewDialogVisible: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onItemClick (item) {
      item.viewMode = true
      this.selectedItem = item
      this.viewDialogVisible = true
    },

    downloadPDF (item) {
      if (!item || !item.Id) return
      const url = `${this.user.OfferDownloadUrl}${item.Id}/${this.authorizationToken}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
