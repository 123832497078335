<template>
  <data-card v-bind="$props">
    <template #toolbar-append>
      <slot name="toolbar-append" />
    </template>
    <template #default>
      <v-card-text>
        <slot
          :data="data"
          :headers="headers"
          name="default"
        >
          <v-simple-table
            :dense="tableDense"
            :fixed-header="fixedHeader"
            :height="tableHeight"
            :style="tableStyle"
          >
            <template #default>
              <slot
                v-if="!vertical"
                :headers="headers"
                name="head"
              >
                <thead v-if="Array.isArray(headers) && headers.length > 0">
                  <tr>
                    <th
                      v-for="(header, h) in headers"
                      :key="h"
                      :class="[header.align ? `text-${header.align}` : 'text-left', 'text-no-wrap']"
                    >
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
              </slot>

              <slot
                v-if="!vertical"
                :data="data"
                :headers="headers"
                name="body"
              >
                <tbody>
                  <tr
                    v-for="(item, i) in data"
                    :key="i"
                  >
                    <template v-for="(header, h) in headers">
                      <td
                        v-if="item.hasOwnProperty(header.value) || header.value === 'actions'"
                        :key="h"
                        :class="header.align ? `text-${header.align}` : 'text-left'"
                      >
                        <slot
                          :header="header"
                          :index="i"
                          :item="item"
                          :name="`item.${header.value}`"
                        >
                          {{ item[header.value] }}
                        </slot>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </slot>
              <slot
                v-else
                :data="data"
                :headers="headers"
                name="body"
              >
                <tbody>
                  <tr
                    v-for="(header, h) in headers"
                    :key="h"
                  >
                    <template v-if="data.hasOwnProperty(header.value)">
                      <td
                        :class="[header.align ? `text-${header.align}` : 'text-left', 'text-no-wrap']"
                        :style="$vuetify.theme.dark ? 'color: rgba(255, 255, 255, 0.7)' : 'color: rgba(0, 0, 0, 0.54)'"
                        class="font-weight-medium text-no-wrap"
                        style="width: 30%;"
                      >
                        {{ header.text }}
                      </td>

                      <td :class="header.align ? `text-${header.align}` : 'text-left'">
                        <slot
                          :header="header"
                          :item="data[header.value]"
                          name="item"
                        >
                          {{ data[header.value] }}
                        </slot>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </slot>
            </template>
          </v-simple-table>
        </slot>
      </v-card-text>
    </template>
  </data-card>
</template>

<script>
import DataCard from '../common/DataCard'

export default {
  name      : 'SimpleTableCard',
  components: { DataCard },
  directives: {},
  mixins    : [],
  props     : {
    ...DataCard.props,

    data: {
      type    : [Array, Object, String],
      required: true
    },
    headers: {
      type   : Array,
      default: () => []
    },
    vertical: {
      type   : Boolean,
      default: false
    },
    tableDense: {
      type: Boolean
    },
    fixedHeader: {
      type   : Boolean,
      default: true
    },
    tableHeight: {
      type   : [String, Number],
      default: undefined
    },
    tableStyle: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
