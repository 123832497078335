import { API, APICall } from '../Api'

export default {
  name: 'Dealers',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.Dealer.Get.Type,
        Endpoint: APICall.Dealer.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Dealer.Save.Type,
        Endpoint: APICall.Dealer.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.Dealer.List.Type,
        Endpoint: APICall.Dealer.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async filters (data) {
      const Request = {
        Type    : APICall.Dealer.Filters.Type,
        Endpoint: APICall.Dealer.Filters.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async areas (data) {
      const Request = {
        Type    : APICall.Dealer.Areas.Type,
        Endpoint: APICall.Dealer.Areas.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allGroups (data) {
      const Request = {
        Type    : APICall.Dealer.Group.List.Type,
        Endpoint: APICall.Dealer.Group.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveUser (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.User.Save.Type,
        Endpoint: APICall.User.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }

}
