<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      max-width="800px"
      persistent
      scrollable
    >
      <data-card
        v-if="editItem"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-text-field
                v-model="editItem.Title"
                :error-messages="serverErrorMessages['Title']"
                :hint="$t(`${parentComponentName}.Form.Fields.Title.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Title.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Title')"
              />

              <v-text-field
                v-model="editItem.CustomerId"
                :error-messages="serverErrorMessages['CustomerId']"
                :hint="$t(`${parentComponentName}.Form.Fields.CustomerId.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.CustomerId.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('CustomerId')"
              />

              <v-text-field
                v-model="editItem.Website"
                :error-messages="serverErrorMessages['Website']"
                :hint="$t(`${parentComponentName}.Form.Fields.Website.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Website.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Website')"
              />

              <v-text-field
                v-model="editItem.Email"
                :error-messages="serverErrorMessages['Email']"
                :hint="$t(`${parentComponentName}.Form.Fields.Email.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Email.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Email')"
              />

              <v-autocomplete
                v-model="editItem.AreaId"
                :clearable="!isReadOnly"
                :error-messages="serverErrorMessages['AreaId']"
                :hint="$t(`${parentComponentName}.Form.Fields.AreaId.Hint`)"
                :items="areasList"
                :label="$t(`${parentComponentName}.Form.Fields.AreaId.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('AreaId')"
                item-text="Name"
                item-value="Id"
              />

              <v-text-field
                v-model="editItem.PostCode"
                :error-messages="serverErrorMessages['PostCode']"
                :hint="$t(`${parentComponentName}.Form.Fields.PostCode.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.PostCode.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('PostCode')"
              />

              <v-text-field
                v-model="editItem.Address"
                :error-messages="serverErrorMessages['Address']"
                :hint="$t(`${parentComponentName}.Form.Fields.Address.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Address.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Address')"
              />

              <v-text-field
                v-model="editItem.Phone"
                :error-messages="serverErrorMessages['Phone']"
                :hint="$t(`${parentComponentName}.Form.Fields.Phone.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Phone.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Phone')"
              />

              <v-text-field
                v-if="editItem.UserDealer"
                v-model="editItem.UserDealer.Fullname"
                :hint="$t(`${parentComponentName}.Form.Fields.UserDealer.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.UserDealer.Label`)"
                :readonly="true"
              />

              <v-switch
                v-model="editItem.Active"
                :error-messages="serverErrorMessages['Active']"
                :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Active')"
                class="d-inline-flex mr-6"
                color="success"
                inset
              />
            </v-form>

            <template v-if="editItem.Id && userCan('Users','ACCESS')">
              <simple-table-card
                :color="$t('Route.Users.Color')"
                :colored-border="true"
                :data="editItem.Users"
                :headers="$t('Dealers.Form.Users.Headers')"
                :icon="$t('Route.Users.Icon')"
                :title="$t('Dealers.Form.Users.Title')"
                dark
                dense
                fixed-header
                table-height="200"
              >
                <template #toolbar-append>
                  <v-btn
                    v-if="!isReadOnly && userCan('Users','ADD')"
                    :color="$t('Route.Users.Color')"
                    :disabled="editItem.loadingAddUser"
                    :loading="editItem.loadingAddUser"
                    class="ml-1 white--text"
                    fab
                    x-small
                    @click.stop="addUser()"
                  >
                    <v-icon>mdi-account-plus</v-icon>
                  </v-btn>
                </template>

                <template #item.GroupId="{item}">
                  <v-icon
                    :color="item.IsAdmin ? 'green' : 'grey lighten-2'"
                    large
                  >
                    {{ item.IsAdmin ? 'mdi-check-circle' : 'mdi-close-circle' }}
                  </v-icon>
                </template>

                <template #item.Active="{item}">
                  <v-switch
                    v-model="item.Active"
                    :readonly="isReadOnly && !userCan('Users','MANAGE')"
                    class="ma-0 pt-0 d-inline-flex"
                    color="success"
                    hide-details
                    inset
                    @change="saveUser(item)"
                  />
                </template>

                <template
                  v-if="!isReadOnly && userCan('Users','MANAGE')"
                  #item.actions="{item}"
                >
                  <v-btn
                    :disabled="item.loadingEdit"
                    :loading="item.loadingEdit"
                    class="ml-1 white--text"
                    color="grey"
                    depressed
                    fab
                    x-small
                    @click.stop="editUser(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </simple-table-card>
            </template>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :disabled="isSaving"
              :loading="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>

    <user-edit-dialog
      v-if="userCan('Users','ADD') || userCan('Users','MANAGE')"
      :dealer="editItem"
      :item="selectedItem"
      :visible.sync="editDialogVisible"
      @saveSuccess="onUserSaveSuccess"
    />
  </v-row>
</template>

<script>
import SimpleTableCard  from '../../components/widgets/SimpleTableCard'
import Dealers          from '../../api/mixins/Dealers'
import DealerModel      from '../../api/mixins/models/DealerModel'
import dialogData       from '../../mixins/dialogData'
import DialogVisible    from '../../mixins/dialogVisible'
import DataCard         from '../../components/common/DataCard'
import Auth             from '../../mixins/auth'
import UserEditDialog   from '../users/UserEditDialog'
import DealerGroupsEnum from './DealerGroupsEnum'

export default {
  name      : 'DealerEditDialog',
  components: {
    DataCard,
    SimpleTableCard,
    UserEditDialog
  },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Dealers, DealerModel, DealerGroupsEnum],
  props     : {
    areasList: {
      type    : Array,
      required: true
    }
  },
  data () {
    return {
      parentComponentName: 'Dealers',
      editDialogVisible  : false,
      selectedItem       : null
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    saveItem () {
      this.formValidate()

      if (!this.formValidate()) return
      this.isSaving = true

      this.editItem.AreaName = this.areasList.find(area => area.Id === this.editItem.AreaId)?.Name || ''

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    addUser () {
      this.selectedItem = null
      this.editDialogVisible = true
    },

    editUser (user) {
      this.selectedItem = JSON.parse(JSON.stringify(user))
      this.editDialogVisible = true
    },

    onUserSaveSuccess () {
      this.get(this.editItem.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.editItem = JSON.parse(JSON.stringify(response.data || this.model || {}))
          }
        })
        .finally(() => {
          this.$emit('saveSuccess')
        })
    }
  }
}
</script>

<style scoped>

</style>
