export default {
  name: 'OfferModel',

  data () {
    return {
      model: {
        Id       : null,
        OrderId  : null,
        Firstname: '',
        Lastname : '',
        Phone    : '',
        Email    : '',
        Car      : '',
        Plate    : '',
        Mileage  : '',
        Tyres    : [
          {
            Brand    : '',
            Tread    : '',
            Dimension: '',
            Price    : '',
            Warranty : '',
            Marketing: {
              Tyrelife: false,
              AB      : false,
              Shell   : false,
              Cosmote : false,
              Notes   : ''
            }
          }
        ],
        AdditionalWorks: {
          ChangeValves   : false,
          Balancing      : false,
          CheckSpareWheel: false,
          CheckRims      : false
        },
        Notes            : '',
        InChargePerson   : '',
        ValidUntilAt     : '',
        NextAppointmentAt: '',
        OfferedAt        : initDate(),
        CustomerEmailAt  : '',
        SendCustomerEmail: false
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        OrderId: {
          required: false,
          type    : 'integer'
        },
        Firstname: {
          required: true,
          minLen  : 2,
          maxLen  : 255,
          type    : 'string'
        },
        Lastname: {
          required: true,
          minLen  : 2,
          maxLen  : 255,
          type    : 'string'
        },
        Email: {
          required: false,
          type    : 'email'
        },
        Phone: {
          required: false,
          length  : 10,
          type    : 'string'
        },
        OfferedAt: {
          required: true,
          type    : 'dateStringSlash'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}

const initDate = () => {
  const d = new Date()
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [day, month, year].join('/')
}
