export default {

  Title: 'Αρχική',

  Reviews: {
    Total: 'Μέσος Όρος'
  },
  Offers: {
    Total: 'Σύνολο'
  },
  Orders: {
    Total: 'Σύνολο'
  }

}
