<template>
  <div>
    <v-app-bar
      app
      clipped-left
      dark
      dense
    >
      <v-app-bar-nav-icon
        v-if="navigationDrawerVisible !== undefined"
        @click.stop="navigationDrawerVisible = !navigationDrawerVisible"
      />

      <v-toolbar-title
        class="hidden-sm-and-down body-2 pl-1"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </v-toolbar-title>

      <div class="flex-grow-1" />

      <v-toolbar-items v-if="items && Array.isArray(items) && items.length > 0">
        <template v-for="(item, i) in items">
          <v-tooltip
            v-if="item.icon"
            :key="`tooltip-${i}`"
            :open-delay="650"
            bottom
          >
            <template #activator="{on}">
              <v-btn
                :key="`btn-${i}`"
                :exact="item.route.exact"
                :to="{name: item.route.name}"
                icon
                v-on="on"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>

          <v-divider
            v-if="item.divider"
            :key="`divider-${i}`"
            vertical
          />
        </template>
      </v-toolbar-items>

      <v-menu
        v-model="userMenu"
        :close-on-content-click="true"
        offset-y
      >
        <template #activator="{on}">
          <v-btn
            :color="$vuetify.breakpoint.smAndDown ? '' : 'indigo'"
            :icon="$vuetify.breakpoint.smAndDown"
            class="ml-2 px-1"
            dark
            depressed
            height="30"
            tile
            v-on="on"
          >
            <v-icon>
              mdi-account-circle
            </v-icon>
            <span
              v-if="user && $vuetify.breakpoint.mdAndUp"
              class="pl-2"
            >{{ user.Username }}</span>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="indigo">
                <v-icon dark>
                  mdi-account-circle
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content v-if="user">
                <v-list-item-title>{{ user.Fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.Username }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              v-if="userCan('Users', 'MANAGE')"
              :icon="$vuetify.breakpoint.smAndDown"
              small
              text
              @click="getUserProfile"
            >
              <v-icon>
                mdi-account-edit
              </v-icon>
              <span
                v-if="$vuetify.breakpoint.mdAndUp"
                class="pl-2"
              >{{ $t('Common.Button.Edit') }}</span>
            </v-btn>

            <v-btn
              color="pred"
              dark
              depressed
              small
              @click="logout"
            >
              <v-icon
                class="pr-2"
              >
                mdi-logout
              </v-icon>
              {{ $t('Auth.Logout.Title') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <img
        v-if="logoVisible"
        :src="'/img/driver-center-logo.png'"
        class="pl-2 hidden-sm-and-down"
        height="30"
      >
    </v-app-bar>

    <user-edit-dialog
      v-if="userCan('Users', 'MANAGE')"
      :item="selectedUser"
      :show-dealer-select="user.GroupId > 1"
      :visible.sync="userDialogVisible"
      @saveSuccess="updateUser"
    />
  </div>
</template>

<script>

import Auth             from '../../mixins/auth'
import UserEditDialog   from '../../views/users/UserEditDialog'
import { API, APICall } from '../../api/Api'

export default {
  name      : 'AppToolbar',
  components: { UserEditDialog },
  directives: {},
  mixins    : [Auth],
  props     : {
    items: {
      type    : Array,
      required: true
    },
    navigationDrawer: {
      type   : Boolean,
      default: undefined
    },
    logoVisible: {
      type   : Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedUser     : null,
      userMenu         : false,
      userDialogVisible: false
    }
  },
  computed: {
    title () {
      const key = `Route.${this.$route.name}.Title`
      return this.$te(key) ? this.$t(key) : this.$t('Common.App.Title')
    },

    navigationDrawerVisible: {
      get () {
        return this.navigationDrawer
      },
      set (val) {
        this.$emit('update:navigationDrawer', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async getUser (id) {
      const Request = {
        Type    : APICall.User.Get.Type,
        Endpoint: APICall.User.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    updateUser () {
      this.selectedUser.Permissions = this.user.Permissions
      this.user = JSON.parse(JSON.stringify(this.selectedUser))
      this.selectedUser = null
    },

    getUserProfile () {
      this.userMenu = false
      this.getUser(this.user.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.selectedUser = response.data
          }
        })
        .finally(() => {
          this.userDialogVisible = true
        })
    }
  }
}
</script>

<style scoped>

</style>
