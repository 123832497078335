export default {
  name: 'ReviewModel',

  data () {
    return {
      model: {
        Id       : null,
        Q1       : '',
        Q2       : '',
        Q3       : '',
        Q4       : '',
        Q5       : '',
        Q6       : '',
        Q7       : '',
        Q8       : '',
        Terms    : false,
        CreatedAt: ''
      },

      validationRules: {},

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
