import { API, APICall } from '../Api'

export default {
  name: 'Reviews',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.Review.Get.Type,
        Endpoint: APICall.Review.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Review.Save.Type,
        Endpoint: APICall.Review.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.Review.List.Type,
        Endpoint: APICall.Review.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }

}
