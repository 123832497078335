export default {
  name: 'DataTable',
  data () {
    return {
      dataTable: {
        loading: false,
        total  : 0,
        search : {
          terms          : '',
          debounceTimeout: null
        },
        resize: {
          enabled        : true,
          debounceTimeout: null,
          bodyHeight     : 0
        },
        options: {
          serverSideEnabled: true,
          multiSort        : false,
          mustSort         : false,
          itemsPerPage     : 10,
          page             : 1,
          sortBy           : [],
          sortDesc         : [true]
        },
        filter               : {},
        filterDebounceTimeout: null,
        headers              : [],
        data                 : []
      }
    }
  },
  watch: {
    'dataTable.search.terms': {
      handler (newVal, oldVal) {
        if (!this.dataTable.options.serverSideEnabled) return
        if (String(newVal).trim() !== String(oldVal).trim() && (String(newVal).trim().length >= 3 || String(newVal).trim().length === 0)) {
          this.dataTable.options.page = 1
          clearTimeout(this.dataTable.search.debounceTimeout)
          this.dataTable.search.debounceTimeout = setTimeout(() => { this.getData() }, 500)
        }
      },
      deep: false
    },
    'dataTable.filter': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.dataTable.options.page = 1
        clearTimeout(this.dataTable.filterDebounceTimeout)
        this.dataTable.filterDebounceTimeout = setTimeout(() => { this.getData() }, 500)
      },
      deep: true
    },
    'dataTable.options': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.getData()
      },
      deep: true
    }
  },
  methods: {
    requestData () {
      const dt = JSON.parse(JSON.stringify(this.dataTable))
      return {
        itemsPerPage: dt.options.itemsPerPage || 10,
        page        : dt.options.page || 1,
        sortBy      : dt.options.sortBy.toString(),
        orderBy     : dt.options.sortDesc.toString() === 'true' ? 'DESC' : 'ASC' || 'DESC',
        search      : dt.search.terms || '',
        filter      : dt.filter
      }
    },

    getData () {
      // eslint-disable-next-line no-console
      console.warn('dataTable MIXIN ::: You must implement "getData" function in your component!')
    },

    calcTableBodyHeight () {
      if (!this.dataTable.resize.enabled) return
      const app = document.getElementsByClassName('v-application--wrap')[0]
      if (!app) return

      const table = app.getElementsByClassName('v-data-table')[0]
      const tableCard = table ? table.parentElement : null
      if (!tableCard) return

      const footer = app.getElementsByTagName('footer')[0]
      const footerHeight = footer ? footer.offsetHeight : 0

      const tableFooter = tableCard.getElementsByClassName('v-data-footer')[0]
      const tableFooterHeight = tableFooter ? tableFooter.offsetHeight : 0

      const cardTitle = tableCard.getElementsByClassName('v-card__title')[0]
      const cardTitleHeight = cardTitle ? cardTitle.offsetHeight : 0

      const toolbar = app.getElementsByTagName('header')[0]
      const toolbarHeight = toolbar ? toolbar.offsetHeight : 64

      this.dataTable.resize.bodyHeight = `calc(100vh - ${toolbarHeight + cardTitleHeight + tableFooterHeight + footerHeight + 24}px)`
    },

    resizeDebounced () {
      if (!this.dataTable.resize.enabled) return
      clearTimeout(this.dataTable.resize.debounceTimeout)
      this.dataTable.resize.debounceTimeout = setTimeout(this.calcTableBodyHeight, 350)
    }
  },

  created () {
    this.calcTableBodyHeight()
    window.addEventListener('resize', this.resizeDebounced)
  },

  mounted () {
    this.calcTableBodyHeight()
  },

  updated () {
    this.calcTableBodyHeight()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeDebounced)
  }

}
