<template>
  <v-row justify="space-between">
    <v-col
      v-if="data.Reviews && userCan('Reviews', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$t('Route.Reviews.Title')}`"
        :sub-title="`${data.Reviews.Average} ${$t('Common.Text.From')} ${data.Reviews.Total} ${$t('Route.Reviews.Title')}`"
        :icon="$t('Route.Reviews.Icon')"
        :to="{name: 'Reviews'}"
        :colored-border="true"
        :color="$t('Route.Reviews.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.Offers && userCan('Offers', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$t('Route.Offers.Title')}`"
        :sub-title="`${data.Offers.Total} ${$tc('Dashboard.Offers.Total', data.Offers.Total)}`"
        :icon="$t('Route.Offers.Icon')"
        :to="{name: 'Offers'}"
        :colored-border="true"
        :color="$t('Route.Offers.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.Orders && userCan('Orders', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$t('Route.Orders.Title')}`"
        :sub-title="`${data.Orders.Total} ${$tc('Dashboard.Orders.Total', data.Orders.Total)}`"
        :icon="$t('Route.Orders.Icon')"
        :to="{name: 'Orders'}"
        :colored-border="true"
        :color="$t('Route.Orders.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>
  </v-row>
</template>

<script>
import Auth              from '../../../mixins/auth'
import MiniStatsIconCard from '../../../components/widgets/MiniStatsIconCard'

export default {
  name      : 'GeneralStats',
  components: { MiniStatsIconCard },
  directives: {},
  mixins    : [Auth],
  props     : {
    data: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    miniStatsCardsColumns () {
      let cols = 0
      let retVal = {
        cols: '12',
        sm  : '6',
        md  : '3'
      }

      if (this.data) {
        if (this.data.Reviews && this.userCan('Reviews', 'ACCESS')) cols++
        if (this.data.Offers && this.userCan('Offers', 'ACCESS')) cols++
        if (this.data.Orders && this.userCan('Orders', 'ACCESS')) cols++
      }

      if (cols === 3) {
        retVal = {
          cols: '12',
          sm  : '4',
          md  : '4'
        }
      }
      if (cols === 2) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '6'
        }
      }
      if (cols === 1) {
        retVal = {
          cols: '12',
          sm  : '12',
          md  : '12'
        }
      }

      return retVal
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
