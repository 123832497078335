export default {

  Title: 'Προσφορές',

  Table: {
    Headers: {
      Id             : '#',
      Firstname      : 'ΟΝΟΜΑ',
      Lastname       : 'ΕΠΩΝΥΜΟ',
      Phone          : 'ΤΗΛΕΦΩΝΟ',
      CustomerEmailAt: 'ΑΠΟΣΤΟΛΗ',
      Email          : 'EMAIL',
      Car            : 'ΟΧΗΜΑ',
      Plate          : 'ΑΡ.ΚΥΚΛ.',
      OfferedAt      : 'ΗΜ/ΝΙΑ',
      Actions        : ''
    }
  },

  Form: {
    Title   : 'Προσφοράς',
    SubTitle: 'Προσφορά',
    Fields  : {
      OfferedAt: {
        Label: 'Ημερομηνία Προσφοράς',
        Hint : 'DD/MM/YYYY'
      },
      ValidUntilAt: {
        Label: 'Η Προσφορά Ισχύει Έως',
        Hint : 'DD/MM/YYYY'
      },
      NextAppointmentAt: {
        Label: 'Ημερομηνία Επόμενου Ραντεβού',
        Hint : 'DD/MM/YYYY'
      },
      InChargePerson: {
        Label: 'Υπέθυνος',
        Hint : ''
      },
      Customer: {
        Title    : 'Στοιχεία Πελάτη',
        Firstname: {
          Label: 'Όνομα',
          Hint : ''
        },
        Lastname: {
          Label: 'Επώνυμο',
          Hint : ''
        },
        Phone: {
          Label: 'Τηλέφωνο',
          Hint : ''
        },
        Email: {
          Label: 'Email',
          Hint : ''
        }
      },
      Car: {
        Title: 'Στοιχεία Οχήματος',
        Car  : {
          Label: 'Όχημα',
          Hint : ''
        },
        Plate: {
          Label: 'Αρ.Κυκλοφορίας',
          Hint : ''
        },
        Mileage: {
          Label: 'Χιλιόμετρα',
          Hint : ''
        }
      },
      Tyres: {
        Title   : 'Προτάσεις Ελαστικών',
        SubTitle: 'Πρόταση',
        Brand   : {
          Label: 'Μάρκα',
          Hint : ''
        },
        Tread: {
          Label: 'Πέλμα',
          Hint : ''
        },
        Dimension: {
          Label: 'Διάσταση',
          Hint : ''
        },
        Price: {
          Label: 'Τιμή',
          Hint : ''
        },
        Warranty: {
          Label: 'Εγγύηση',
          Hint : ''
        },
        Marketing: {
          Title   : 'Ενέργειες Marketing',
          Tyrelife: {
            Label: 'Tyrelife',
            Hint : ''
          },
          AB: {
            Label: 'AB',
            Hint : ''
          },
          Shell: {
            Label: 'Shell',
            Hint : ''
          },
          Cosmote: {
            Label: 'Cosmote',
            Hint : ''
          },
          Notes: {
            Label: 'Παρατηρήσεις',
            Hint : ''
          }
        }
      },
      AdditionalWorks: {
        Title       : 'Πρόσθετες Εργασίες',
        ChangeValves: {
          Label: 'Αλλαγή Βαλβίδων',
          Hint : ''
        },
        Balancing: {
          Label: 'Ζυγοστάθμιση',
          Hint : ''
        },
        CheckSpareWheel: {
          Label: 'Έλεγχος Ρεζέρβας',
          Hint : ''
        },
        CheckRims: {
          Label: 'Έλεγχος Ζαντών',
          Hint : ''
        }
      },
      Notes: {
        Title: '@:Offers.Form.Fields.Notes.Label',
        Label: 'Παρατηρήσεις',
        Hint : ''
      }
    },
    Filters: {
      DateRange: 'Ημ/νία Προσφοράς'
    }
  },

  Button: {
    New     : 'ΝΕΑ ΠΡΟΣΦΟΡΑ',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  },

  ConfirmDialog: {
    Title   : 'Διαγραφή Προσφοράς',
    SubTitle: 'Προσφορά',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να διαγράψετε την προσφορά;</div><br>
      <div class="font-weight-regular text-left">Η διαδικασία είναι <span class="font-weight-bold">ΜΗ</span> αναστρέψιμη και προχωρώντας η προσφορά Θα διαγραφεί <span class="font-weight-bold">ΟΡΙΣΤΙΚΑ</span>!</div><br>
      <div class="font-weight-regular error--text text-left">Η διαγραφή προσφοράς <span class="font-weight-bold">ΔΕΝ</span> μπορεί να αντιστραφεί.</div>
    `
  }

}
