<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #item.Q1="{item}">
              <v-rating
                v-model="item.Q1"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q2="{item}">
              <v-rating
                v-model="item.Q2"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q3="{item}">
              <v-rating
                v-model="item.Q3"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q4="{item}">
              <v-rating
                v-model="item.Q4"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q5="{item}">
              <v-rating
                v-model="item.Q5"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q6="{item}">
              <v-rating
                v-model="item.Q6"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q7="{item}">
              <v-rating
                v-model="item.Q7"
                background-color="orange lighten-3"
                color="orange"
                dense
                small
                readonly
              />
            </template>

            <template #item.Q8="{item}">
              {{ truncateString(item.Q8, 50) }}
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  class="ml-1 white--text"
                  color="primary"
                  depressed
                  fab
                  x-small
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <view-dialog
      v-if="userCanAccess"
      :item="selectedItem"
      :visible.sync="editDialogVisible"
    />
  </v-container>
</template>

<script>
import Auth               from '../../mixins/auth'
import DataTable          from '../../mixins/dataTable'
import DataTableActions   from '../../mixins/dataTableActions'
import Review             from '../../api/mixins/Reviews'
import ViewDialog         from './ReviewViewDialog'
import { truncateString } from '../../lib/utils/string/index'

export default {
  name      : 'Reviews',
  components: { ViewDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Review],
  props     : {},
  data () {
    return {
      dataTable: {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active: 'all'
        },
        headers: [
          {
            text    : this.$t('Reviews.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q1'),
            value   : 'Q1',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q2'),
            value   : 'Q2',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q3'),
            value   : 'Q3',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q4'),
            value   : 'Q4',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q5'),
            value   : 'Q5',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q6'),
            value   : 'Q6',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q7'),
            value   : 'Q7',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Q8'),
            value   : 'Q8',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Reviews.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () { },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    truncateString,

    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
