export default {

  Title: 'Dealers',

  Table: {
    Headers: {
      Id        : '#',
      Title     : 'ΕΠΩΝΥΜΙΑ',
      AreaName  : 'ΠΕΡΙΟΧΗ',
      CustomerId: 'ΚΩΔΙΚΟΣ',
      GroupName : 'ΟΜΑΔΑ',
      UsersCount: 'ΧΡΗΣΤΕΣ',
      UserDealer: 'ΠΩΛΗΤΗΣ',
      Active    : 'ΕΝΕΡΓΟΣ',
      CreatedAt : 'ΕΓΓΡΑΦΗ',
      Actions   : ''
    }
  },

  Form: {
    Title : 'Dealer',
    Fields: {
      Title: {
        Label: 'Τίτλος',
        Hint : ''
      },
      CustomerId: {
        Label: 'Κωδικός',
        Hint : ''
      },
      DealerCode: {
        Label: 'Κωδικός Dealer',
        Hint : 'Εισάγετε κωδικό dealer',
        Error: {
          NoResults: 'Δεν βρέθηκαν συναλλαγές αντικατάστασης για τον dealer.'
        }
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      Website: {
        Label: 'Website',
        Hint : ''
      },
      AreaId: {
        Label: 'Περιοχή',
        Hint : ''
      },
      PostCode: {
        Label: 'Τ.Κ.',
        Hint : ''
      },
      Address: {
        Label: 'Διεύθυνση',
        Hint : ''
      },
      Phone: {
        Label: 'Τηλέφωνο',
        Hint : ''
      },
      UserDealer: {
        Label: 'Πωλητής',
        Hint : ''
      },
      GroupId: {
        Label: 'Ομάδα',
        Hint : ''
      },
      Users: {
        Label: 'Χρήστες',
        Hint : ''
      },
      Active: {
        Label: 'Dealer Ενεργός',
        Hint : ''
      }
    },
    Filters: {},
    Users  : {
      Title  : 'Χρήστες',
      Headers: [
        {
          text : 'USERNAME',
          value: 'Username',
          align: 'left'
        },
        {
          text : 'ΟΝΟΜΑ',
          value: 'Firstname',
          align: 'left'
        },
        {
          text : 'ΕΠΩΝΥΜΟ',
          value: 'Lastname',
          align: 'left'
        },
        {
          text : 'ΔΙΑΧΕΙΡΙΣΤΗΣ',
          value: 'GroupId',
          align: 'center'
        },
        {
          text : 'ΕΝΕΡΓΟΣ',
          value: 'Active',
          align: 'center'
        },
        {
          text : '',
          value: 'actions',
          align: 'center'
        }
      ]
    }
  },

  Button: {
    New     : 'Νέος Dealer',
    All     : 'ΟΛΟΙ',
    Active  : 'ΕΝΕΡΓΟΙ',
    Inactive: 'ΑΝΕΝΕΡΓΟΙ'
  }

}
