<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <data-card
        v-if="editItem"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${$t('Common.Button.View')} ${$t(`${parentComponentName}.Form.Title`)} #${editItem.Id}`"
        :sub-title="`${$t(`${parentComponentName}.Form.SubTitle`)} ${editItem.CreatedAt}`"
      >
        <template #toolbar-append>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <template v-for="(q) in 8">
              <v-row :key="q">
                <v-col
                  cols="12"
                  style="max-height: 54px;"
                >
                  <v-alert
                    border="left"
                    :color="$t(`Route.${parentComponentName}.Color`)"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Q${q}.Number`) }}. {{ $t(`${parentComponentName}.Form.Fields.Q${q}.Question`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <div v-if="q===8" class="px-4 py-7">
                    {{ editItem[`Q${q}`] }}
                  </div>

                  <v-rating
                    v-else
                    v-model="editItem[`Q${q}`]"
                    background-color="orange lighten-3"
                    color="orange"
                    readonly
                  />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              text
              @click="closeDialog"
            >
              {{ $t('Common.Button.Close') }}
            </v-btn>
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Reviews       from '../../api/mixins/Reviews'
import ReviewModel   from '../../api/mixins/models/ReviewModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'ReviewViewDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Reviews, ReviewModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Reviews',
      editDialogVisible  : false,
      selectedItem       : null
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
