/**
 * Returns the current URL.
 *
 * Use window.location.href to get current URL.
 *
 * @example currentURL(); // 'https://google.com'
 *
 * @return {string}
 */
export const currentURL = () => window.location.href

/**
 * Copy a string to the clipboard. Only works as a result of user action (i.e. inside a click event listener).
 *
 * Create a new <textarea> element, fill it with the supplied data and add it to the HTML document.
 * Use Selection.getRangeAt()to store the selected range (if any). Use document.execCommand('copy') to copy to the clipboard.
 * Remove the <textarea> element from the HTML document. Finally, use Selection().addRange() to recover the original selected range (if any).
 *
 * NOTICE: The same functionality can be easily implemented by using the new asynchronous Clipboard API,
 *         which is still experimental but should be used in the future instead of this snippet.
 *         Find out more about it here (https://github.com/w3c/clipboard-apis/blob/master/explainer.adoc#writing-to-the-clipboard).
 *
 * @example copyToClipboard('Lorem ipsum'); // 'Lorem ipsum' copied to clipboard.
 *
 * @param str
 */
export const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

/**
 * Creates an element from a string (without appending it to the document). If the given string contains multiple elements, only the first one will be returned.
 *
 * Use document.createElement() to create a new element. Set its innerHTML to the string supplied as the argument.
 * Use ParentNode.firstElementChild to return the element version of the string.
 *
 * @example const el = createElement(
 * `<div class="container">
 * <p>Hello!</p>
 * </div>`
 * );
 * console.log(el.className); // 'container'
 *
 * @param str
 * @return {Element}
 */
export const createElement = str => {
  const el = document.createElement('div')
  el.innerHTML = str
  return el.firstElementChild
}

/**
 * Creates a pub/sub (publish–subscribe) event hub with emit, on, and off methods.
 *
 * Use Object.create(null) to create an empty hub object that does not inherit properties from Object.prototype.
 * For emit, resolve the array of handlers based on the event argument and then run each one with Array.prototype.forEach() by passing in the data as an argument.
 * For on, create an array for the event if it does not yet exist, then use Array.prototype.push() to add the handler to the array.
 * For off, use Array.prototype.findIndex() to find the index of the handler in the event array and remove it using Array.prototype.splice().
 *
 * @example
 * const handler = data => console.log(data);
 * const hub = createEventHub();
 * let increment = 0;
 *
 * // Subscribe: listen for different types of events
 * hub.on('message', handler);
 * hub.on('message', () => console.log('Message event fired'));
 * hub.on('increment', () => increment++);
 *
 * // Publish: emit events to invoke all handlers subscribed to them, passing the data to them as an argument
 * hub.emit('message', 'hello world'); // logs 'hello world' and 'Message event fired'
 * hub.emit('message', { hello: 'world' }); // logs the object and 'Message event fired'
 * hub.emit('increment'); // `increment` variable is now 1
 *
 * // Unsubscribe: stop a specific handler from listening to the 'message' event
 * hub.off('message', handler);
 *
 * @return {{hub: *, emit(*, *=): void, off(*, *): void, on(*, *=): void}}
 */
export const createEventHub = () => ({
  hub: Object.create(null),
  emit (event, data) {
    (this.hub[event] || []).forEach(handler => handler(data))
  },
  on (event, handler) {
    if (!this.hub[event]) this.hub[event] = []
    this.hub[event].push(handler)
  },
  off (event, handler) {
    const i = (this.hub[event] || []).findIndex(h => h === handler)
    if (i > -1) this.hub[event].splice(i, 1)
    if (this.hub[event].length === 0) delete this.hub[event]
  }
})

/**
 * Detects whether the website is being opened in a mobile device or a desktop/laptop.
 *
 * Use a regular expression to test the navigator.userAgent property to figure out if the device is a mobile device or a desktop/laptop.
 *
 * @example detectDeviceType(); // "Mobile" or "Desktop"
 *
 * @return {string}
 */
export const detectDeviceType = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'

/**
 * Returns true if the parent element contains the child element, false otherwise.
 *
 * Check that parent is not the same element as child, use parent.contains(child) to check if the parent element contains the child element.
 *
 * @example
 *
 * @param parent
 * @param child
 * @return {boolean}
 */
export const elementContains = (parent, child) => parent !== child && parent.contains(child)

/**
 * Returns true if the element specified is visible in the viewport, false otherwise.
 *
 * Use Element.getBoundingClientRect() and the window.inner(Width|Height) values to determine if a given element is visible in the viewport.
 * Omit the second argument to determine if the element is entirely visible, or specify true to determine if it is partially visible.
 *
 * @example // e.g. 100x100 viewport and a 10x10px element at position {top: -1, left: 0, bottom: 9, right: 10}
 * @example elementIsVisibleInViewport(el); // false - (not fully visible)
 * @example elementIsVisibleInViewport(el, true); // true - (partially visible)
 *
 * @param el
 * @param partiallyVisible
 * @return {boolean}
 */
export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const {
    top,
    left,
    bottom,
    right
  } = el.getBoundingClientRect()
  const {
    innerHeight,
    innerWidth
  } = window
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
    ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
}

/**
 * Encode a set of form elements as an object.
 *
 * Use the FormData constructor to convert the HTML form to FormData, Array.from() to convert to an array.
 * Collect the object from the array, using Array.prototype.reduce().
 *
 * @example formToObject(document.querySelector('#form')); // { email: 'test@email.com', name: 'Test Name' }
 *
 * @param form
 * @return {{}|{[p: string]: *}}
 */
export const formToObject = form => Array.from(new FormData(form)).reduce((acc, [key, value]) => ({
  ...acc,
  [key]: value
}), {})

/**
 * Fetches all images from within an element and puts them into an array
 *
 * Use Element.prototype.getElementsByTagName() to fetch all <img> elements inside the provided element, Array.prototype.map()
 * to map every src attribute of their respective <img> element, then create a Set to eliminate duplicates and return the array.
 *
 * @example getImages(document, true); // ['image1.jpg', 'image2.png', 'image1.png', '...']
 * @example getImages(document, false); // ['image1.jpg', 'image2.png', '...']
 *
 * @param el
 * @param includeDuplicates
 * @return {(*|ASTNode|string)[]}
 */
export const getImages = (el, includeDuplicates = false) => {
  const images = [...el.getElementsByTagName('img')].map(img => img.getAttribute('src'))
  return includeDuplicates ? images : [...new Set(images)]
}

/**
 * Returns the scroll position of the current page.
 *
 * Use pageXOffset and pageYOffset if they are defined, otherwise scrollLeft and scrollTop. You can omit el to use a default value of window.
 *
 * @example getScrollPosition(); // {x: 0, y: 200}
 *
 * @param el
 * @return {{x: (*), y: (*)}}
 */
export const getScrollPosition = (el = window) => ({
  x: el.pageXOffset !== undefined ? el.pageXOffset : el.scrollLeft,
  y: el.pageYOffset !== undefined ? el.pageYOffset : el.scrollTop
})

/**
 * Returns the value of a CSS rule for the specified element.
 *
 * Use Window.getComputedStyle() to get the value of the CSS rule for the specified element.
 *
 * @example getStyle(document.querySelector('p'), 'font-size'); // '16px'
 *
 * @param el
 * @param ruleName
 * @return {string}
 */
export const getStyle = (el, ruleName) => getComputedStyle(el)[ruleName]

/**
 * Returns true if the element has the specified class, false otherwise.
 *
 * Use element.classList.contains() to check if the element has the specified class.
 *
 * @example hasClass(document.querySelector('p.special'), 'special'); // true
 *
 * @param el
 * @param className
 * @return {boolean}
 */
export const hasClass = (el, className) => el.classList.contains(className)

/**
 * Creates a hash for a value using the SHA-256 algorithm. Returns a promise.
 *
 * Use the SubtleCrypto API (https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto)
 * to create a hash for the given value.
 *
 * @example hashBrowser(JSON.stringify({ a: 'a', b: [1, 2, 3, 4], foo: { c: 'bar' } })).then(console.log); // '04aa106279f5977f59f9067fa9712afc4aedc6f5862a8defc34552d8c7206393'
 *
 * @param val
 * @return {PromiseLike<string>}
 */
export const hashBrowser = val => crypto.subtle.digest('SHA-256', new TextEncoder('utf-8').encode(val)).then(h => {
  const hexes = []
  const view = new DataView(h)
  for (let i = 0; i < view.byteLength; i += 4) hexes.push(('00000000' + view.getUint32(i).toString(16)).slice(-8))
  return hexes.join('')
})

/**
 * Hides all the elements specified.
 *
 * Use NodeList.prototype.forEach() to apply display: none to each element specified.
 *
 * @example hide(document.querySelectorAll('img')); // Hides all <img> elements on the page
 *
 * @param el
 */
export const hide = (...el) => [...el].forEach(e => (e.style.display = 'none'))

/**
 * Inserts an HTML string after the end of the specified element.
 *
 * Use el.insertAdjacentHTML() with a position of 'afterend' to parse htmlString and insert it after the end of el.
 *
 * @example insertAfter(document.getElementById('myId'), '<p>after</p>'); // <div id="myId">...</div> <p>after</p>
 *
 * @param el
 * @param htmlString
 */
export const insertAfter = (el, htmlString) => el.insertAdjacentHTML('afterend', htmlString)

/**
 * Inserts an HTML string before the start of the specified element.
 *
 * Use el.insertAdjacentHTML() with a position of 'beforebegin' to parse htmlString and insert it before the start of el.
 *
 * @example insertBefore(document.getElementById('myId'), '<p>before</p>'); // <p>before</p> <div id="myId">...</div>
 *
 * @param el
 * @param htmlString
 */
export const insertBefore = (el, htmlString) => el.insertAdjacentHTML('beforebegin', htmlString)

/**
 * Determines if the current runtime environment is a browser so that front-end modules can run on the server (Node) without throwing errors.
 *
 * se Array.prototype.includes() on the typeof values of both window and document (globals usually only available in a browser environment
 * unless they were explicitly defined), which will return true if one of them is undefined. typeof allows globals to be checked for
 * existence without throwing a ReferenceError. If both of them are not undefined, then the current environment is assumed to be a browser.
 *
 * @example isBrowser(); // true (browser)
 * @example isBrowser(); // false (Node)
 *
 * @returns {boolean}
 */
export const isBrowser = () => ![typeof window, typeof document].includes('undefined')

/**
 * Returns true if the browser tab of the page is focused, false otherwise.
 *
 * Use the Document.hidden property, introduced by the Page Visibility API to check if the browser tab of the page is visible or hidden.
 *
 * @example isBrowserTabFocused(); // true
 *
 * @return {boolean}
 */
export const isBrowserTabFocused = () => !document.hidden

/**
 * Returns a query string generated from the key-value pairs of the given object.
 *
 * Use Array.prototype.reduce() on Object.entries(queryParameters) to create the query string.
 * Determine the symbol to be either ? or & based on the index and concatenate val to queryString
 * only if it's a string. Return the queryString or an empty string when the queryParameters are falsy.
 *
 * @example objectToQueryString({ page: '1', size: '2kg', key: undefined }); // '?page=1&size=2kg'
 *
 * @param queryParameters
 * @returns {string}
 */
export const objectToQueryString = queryParameters => {
  return queryParameters
    ? Object.entries(queryParameters).reduce((queryString, [key, val], index) => {
      const symbol = index === 0 ? '?' : '&'
      queryString += typeof val === 'string' ? `${symbol}${key}=${val}` : ''
      return queryString
    }, '')
    : ''
}

/**
 * Returns a new MutationObserver and runs the provided callback for each mutation on the specified element.
 *
 * se a MutationObserver to observe mutations on the given element. Use Array.prototype.forEach() to run the callback for each mutation that is observed.
 * Omit the third argument, options, to use the default options (all true).
 *
 * @example
 * const obs = observeMutations(document, console.log); // Logs all mutations that happen on the page
 * obs.disconnect(); // Disconnects the observer and stops logging mutations on the page
 *
 * @param element
 * @param callback
 * @param options
 * @return {MutationObserver}
 */
export const observeMutations = (element, callback, options) => {
  const observer = new MutationObserver(mutations => mutations.forEach(m => callback(m)))
  observer.observe(
    element,
    Object.assign(
      {
        childList            : true,
        attributes           : true,
        attributeOldValue    : true,
        characterData        : true,
        characterDataOldValue: true,
        subtree              : true
      },
      options
    )
  )
  return observer
}

/**
 * Adds an event listener to an element with the ability to use event delegation.
 *
 * Use EventTarget.addEventListener() to add an event listener to an element.
 * If there is a target property supplied to the options object, ensure the
 * event target matches the target specified and then invoke the callback by
 * supplying the correct this context. Returns a reference to the custom delegator
 * function, in order to be possible to use with off. Omit opts to default to
 * non-delegation behavior and event bubbling.
 *
 * @example const fn = () => console.log('!');
 * @example on(document.body, 'click', fn); // logs '!' upon clicking the body
 * @example on(document.body, 'click', fn, { target: 'p' }); // logs '!' upon clicking a `p` element child of the body
 * @example on(document.body, 'click', fn, { options: true }); // use capturing instead of bubbling
 *
 * @param el
 * @param evt
 * @param fn
 * @param opts
 * @return {function(*=): (boolean | *)}
 */
export const on = (el, evt, fn, opts = {}) => {
  const delegatorFn = e => e.target.matches(opts.target) && fn.call(e.target, e)
  el.addEventListener(evt, opts.target ? delegatorFn : fn, opts.options || false)
  if (opts.target) return delegatorFn
}

/**
 * Removes an event listener from an element.
 *
 * Use EventTarget.removeEventListener() to remove an event listener from an element.
 * Omit the fourth argument opts to use false or specify it based on the options used when the event listener was added.
 *
 * @example
 * const fn = () => console.log('!');
 * document.body.addEventListener('click', fn);
 * off(document.body, 'click', fn); // no longer logs '!' upon clicking on the page
 *
 * @param el
 * @param evt
 * @param fn
 * @param opts
 * @return {*}
 */
export const off = (el, evt, fn, opts = false) => el.removeEventListener(evt, fn, opts)

/**
 * Invokes the provided callback on each animation frame.
 *
 * Use recursion. Provided that running is true, continue invoking window.requestAnimationFrame()
 * which invokes the provided callback. Return an object with two methods start and stop to allow
 * manual control of the recording. Omit the second argument, autoStart, to implicitly call start
 * when the function is invoked.
 *
 * @example
 * const cb = () => console.log('Animation frame fired');
 * const recorder = recordAnimationFrames(cb); // logs 'Animation frame fired' on each animation frame
 * recorder.stop(); // stops logging
 * recorder.start(); // starts again
 * const recorder2 = recordAnimationFrames(cb, false); // `start` needs to be explicitly called to begin recording frames
 *
 * @param callback
 * @param autoStart
 * @return {{stop: *, start: *}}
 */
export const recordAnimationFrames = (callback, autoStart = true) => {
  let running = true
  let raf
  const stop = () => {
    running = false
    cancelAnimationFrame(raf)
  }
  const start = () => {
    running = true
    run()
  }
  const run = () => {
    raf = requestAnimationFrame(() => {
      callback()
      if (running) run()
    })
  }
  if (autoStart) start()
  return {
    start,
    stop
  }
}

/**
 * Runs a function in a separate thread by using a Web Worker, allowing long running functions to not block the UI.
 *
 * Create a new Worker using a Blob object URL, the contents of which should be the stringified version of the supplied function.
 * Immediately post the return value of calling the function back. Return a promise, listening for onmessage and onerror events
 * and resolving the data posted back from the worker, or throwing an error.
 *
 * @example
 * const longRunningFunction = () => {
 * let result = 0;
 * for (let i = 0; i < 1000; i++)
 * for (let j = 0; j < 700; j++) for (let k = 0; k < 300; k++) result = result + i + j + k;
 *
 * return result;
 * };
 *
 ***************************************************************************************************
 * NOTE: Since the function is running in a different context, closures are not supported.
 *       The function supplied to `runAsync` gets stringified, so everything becomes literal.
 *       All variables and functions must be defined inside.
 ***************************************************************************************************
 *
 * runAsync(longRunningFunction).then(console.log); // 209685000000
 * runAsync(() => 10 ** 3).then(console.log); // 1000
 * let outsideVariable = 50;
 * runAsync(() => typeof outsideVariable).then(console.log); // 'undefined'
 *
 * @param fn
 * @return {Promise<unknown>}
 */
export const runAsync = fn => {
  const worker = new Worker(
    URL.createObjectURL(new Blob([`postMessage((${fn})());`]), {
      type: 'application/javascript; charset=utf-8'
    })
  )
  // eslint-disable-next-line promise/param-names
  return new Promise((res, rej) => {
    worker.onmessage = ({ data }) => {
      res(data)
      worker.terminate()
    }
    worker.onerror = err => {
      rej(err)
      worker.terminate()
    }
  })
}

/**
 * Smooth-scrolls to the top of the page.
 *
 * Get distance from top using document.documentElement.scrollTop or document.body.scrollTop.
 * Scroll by a fraction of the distance from the top. Use window.requestAnimationFrame() to animate the scrolling.
 *
 * @example scrollToTop();
 */
export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop)
    window.scrollTo(0, c - c / 8)
  }
}

/**
 * Encode a set of form elements as a query string.
 *
 * Use the FormData constructor to convert the HTML form to FormData, Array.from() to convert to an array,
 * passing a map function as the second argument. Use Array.prototype.map() and window.encodeURIComponent()
 * to encode each field's value. Use Array.prototype.join() with appropriate argumens to produce an appropriate
 * query string.
 *
 * @example serializeForm(document.querySelector('#form')); // email=test%40email.com&name=Test%20Name
 *
 * @param form
 * @return {string}
 */
export const serializeForm = form => Array.from(new FormData(form), field => field.map(encodeURIComponent).join('=')).join('&')

/**
 * Sets the value of a CSS rule for the specified element.
 *
 * Use element.style to set the value of the CSS rule for the specified element to val.
 *
 * @example setStyle(document.querySelector('p'), 'font-size', '20px'); // The first <p> element on the page will have a font-size of 20px
 *
 * @param el
 * @param ruleName
 * @param val
 * @return {*}
 */
export const setStyle = (el, ruleName, val) => (el.style[ruleName] = val)

/**
 * Shows all the elements specified.
 *
 * Use the spread operator (...) and Array.prototype.forEach() to clear the display property for each element specified.
 *
 * @example show(...document.querySelectorAll('img')); // Shows all <img> elements on the page
 *
 * @param el
 */
export const show = (...el) => [...el].forEach(e => (e.style.display = ''))

/**
 * Smoothly scrolls the element on which it's called into the visible area of the browser window.
 *
 * Use .scrollIntoView method to scroll the element. Pass { behavior: 'smooth' } to .scrollIntoView so it scrolls smoothly.
 *
 * @example smoothScroll('#fooBar'); // scrolls smoothly to the element with the id fooBar
 * @example smoothScroll('.fooBar'); // scrolls smoothly to the first element with a class of fooBar
 *
 * @param element
 * @return {*|undefined|void}
 */
export const smoothScroll = element => document.querySelector(element).scrollIntoView({ behavior: 'smooth' })

/**
 * Toggle a class for an element.
 *
 * Use element.classList.toggle() to toggle the specified class for the element.
 *
 * @example toggleClass(document.querySelector('p.special'), 'special'); // The paragraph will not have the 'special' class anymore
 *
 * @param el
 * @param className
 * @return {boolean}
 */
export const toggleClass = (el, className) => el.classList.toggle(className)

/**
 * Triggers a specific event on a given element, optionally passing custom data.
 *
 * Use new CustomEvent() to create an event from the specified eventType and details.
 * Use el.dispatchEvent() to trigger the newly created event on the given element.
 * Omit the third argument, detail, if you do not want to pass custom data to the triggered event.
 *
 * @example triggerEvent(document.getElementById('myId'), 'click');
 * @example triggerEvent(document.getElementById('myId'), 'click', { username: 'bob' });
 *
 * @param el
 * @param eventType
 * @param detail
 * @return {boolean}
 */
export const triggerEvent = (el, eventType, detail) => el.dispatchEvent(new CustomEvent(eventType, { detail }))

/**
 * Generates a UUID in a browser.
 *
 * Use crypto API to generate a UUID, compliant with RFC4122 version 4.
 *
 * @example UUIDGeneratorBrowser(); // '7982fcfe-5721-4632-bede-6000885be57d'
 *
 * @return {*}
 * @constructor
 */
export const UUIDGeneratorBrowser = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))

/**
 * Returns an object containing the parameters of the current URL.
 *
 * Use String.match() with an appropriate regular expression to get all key-value pairs,
 * Array.prototype.reduce() to map and combine them into a single object.
 * Pass location.search as the argument to apply to the current url.
 *
 * @example getURLParameters('http://url.com/page?name=Adam&surname=Smith'); // {name: 'Adam', surname: 'Smith'}
 * @example getURLParameters('google.com'); // {}
 *
 * @param url
 * @returns {{}}
 */
export const getURLParameters = url => (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a), {})
