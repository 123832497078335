<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  class="px-2"
                  color="primary"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Offers.Button.New') }}
                </v-btn>

                <v-btn
                  v-if="userCanAccess"
                  class="px-2 ml-2"
                  color="teal"
                  dark
                  @click="downloadPDF({Id: 0})"
                >
                  <v-icon class="mr-1">
                    mdi-download
                  </v-icon>
                  {{ $t('Common.Button.Download') }} PDF
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  clearable
                  hide-details
                  max-width="100"
                  prepend-icon="mdi-magnify"
                  single-line
                />
              </template>

              <template #extension>
                <div class="flex-grow-1" />

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('Offers.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class=""
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #item.CustomerEmailAt="{item}">
              <v-icon
                v-if="item.CustomerEmailAt"
                color="success"
                class="mr-4"
              >
                mdi-email-send-outline
              </v-icon>
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanDelete"
                  :disabled="item.loadingDelete"
                  :loading="item.loadingDelete"
                  class="ml-1 white--text"
                  color="red"
                  depressed
                  fab
                  x-small
                  @click.stop="deleteItem(item)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingDownload"
                  :loading="item.loadingDownload"
                  class="ml-1 white--text"
                  color="teal"
                  fab
                  depressed
                  x-small
                  @click="downloadPDF(item)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  class="ml-1 white--text"
                  color="primary"
                  depressed
                  fab
                  x-small
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  class="ml-1 white--text"
                  color="grey"
                  depressed
                  fab
                  x-small
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :item="selectedItem"
      :visible.sync="editDialogVisible"
      @saveSuccess="getData"
    />

    <confirm-dialog
      v-if="userCanDelete || userCanManage"
      ref="confirm"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Offers           from '../../api/mixins/Offers'
import ConfirmDialog    from '../../components/common/ConfirmDialog'
import EditDialog       from './OfferEditDialog'

export default {
  name      : 'Offers',
  components: {
    EditDialog,
    ConfirmDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Offers],
  props     : {},
  data () {
    return {
      dataTable: {
        dateRange    : [],
        dateRangeMenu: false,
        options      : {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Date: []
        },
        headers: [
          {
            text    : this.$t('Offers.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Firstname'),
            value   : 'Firstname',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Lastname'),
            value   : 'Lastname',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Phone'),
            value   : 'Phone',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.CustomerEmailAt'),
            value   : 'CustomerEmailAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Email'),
            value   : 'Email',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Car'),
            value   : 'Car',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Plate'),
            value   : 'Plate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.OfferedAt'),
            value   : 'OfferedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Offers.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () { },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    downloadPDF (item) {
      if (!item) return
      const url = `${this.user.OfferDownloadUrl}${item.Id}/${this.authorizationToken}`
      window.open(url, '_blank')
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },

    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    },

    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    deleteItem (item) {
      if (!this.userCanDelete) return

      this.selectedItem = JSON.parse(JSON.stringify(item))
      item.loadingDelete = true

      this.$refs.confirm.open(
        this.$t('Offers.ConfirmDialog.Title'),
        `${this.selectedItem.Firstname} ${this.selectedItem.Lastname} #${this.selectedItem.Id}`,
        this.$t('Offers.ConfirmDialog.Body'),
        {
          color   : 'error',
          icon    : 'mdi-alert',
          validate: {
            text  : this.$t('Common.Confirm.ValidateDelete.Text', { answer: this.selectedItem.Id }),
            answer: this.selectedItem.Id
          },
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Offers.ConfirmDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.delete(item.Id)
            .then(response => {
              if (response && response.status === 200 && response.data && response.data.status === 'success' && response.data.data === true) this.getData()
            })
            .finally(() => {
              item.loadingDelete = false
              this.$forceUpdate()
            })
        } else {
          item.loadingDelete = false
          this.$forceUpdate()
        }
      })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    }
  }
}
</script>

<style scoped>
/deep/ label.v-label {
  pointer-events : none;
}
</style>
