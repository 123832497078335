var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"flat":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.userCanAdd)?_c('v-btn',{staticClass:"px-2",attrs:{"color":"primary"},on:{"click":_vm.createItem}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('Orders.Button.New'))+" ")],1):_vm._e(),(_vm.userCanAccess)?_c('v-btn',{staticClass:"px-2 ml-2",attrs:{"color":"teal","dark":""},on:{"click":function($event){return _vm.downloadPDF({Id: 0})}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-download ")]),_vm._v(" "+_vm._s(_vm.$t('Common.Button.Download'))+" PDF ")],1):_vm._e(),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"label":_vm.$t('Common.Search.Title'),"clearable":"","hide-details":"","max-width":"100","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.dataTable.search.terms),callback:function ($$v) {_vm.$set(_vm.dataTable.search, "terms", $$v)},expression:"dataTable.search.terms"}})]},proxy:true},{key:"extension",fn:function(){return [_c('div',{staticClass:"flex-grow-1"}),_c('v-menu',{ref:"dateRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dataTable.dateRange,"min-width":"290px","max-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.dataTable, "dateRange", $event)},"update:return-value":function($event){return _vm.$set(_vm.dataTable, "dateRange", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"max-width":"250px"},attrs:{"label":_vm.$t('Orders.Form.Filters.DateRange'),"append-icon":_vm.dateRangeText ? 'mdi-close' : '',"hide-details":"","dense":"","flat":"","outlined":"","readonly":""},on:{"click:append":_vm.onDateRangeClear},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.dataTable.dateRangeMenu),callback:function ($$v) {_vm.$set(_vm.dataTable, "dateRangeMenu", $$v)},expression:"dataTable.dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dataTable.dateRange),callback:function ($$v) {_vm.$set(_vm.dataTable, "dateRange", $$v)},expression:"dataTable.dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dataTable.dateRangeMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveDateRange}},[_vm._v(" OK ")])],1)],1)]},proxy:true}])})],1),_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions: [5,10,15,20,25]},"headers":_vm.dataTable.headers,"height":_vm.dataTable.resize.bodyHeight,"items":_vm.dataTable.data,"loading":_vm.dataTable.loading,"options":_vm.dataTable.options,"search":_vm.dataTable.search.terms,"server-items-length":_vm.dataTable.total,"fixed-header":"","item-key":"Id"},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.OfferId",fn:function(ref){
var item = ref.item;
return [(item.OfferId && _vm.userCan('Offers', 'ACCESS'))?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":item.loadingOffer,"loading":item.loadingOffer,"color":"success","depressed":"","plain":"","icon":"","small":""},on:{"click":function($event){return _vm.viewOffer(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$t('Route.Offers.Icon')))])],1):_vm._e()]}},{key:"item.CustomerEmailAt",fn:function(ref){
var item = ref.item;
return [(item.CustomerEmailAt)?_c('v-icon',{staticClass:"mr-4",attrs:{"color":"success"}},[_vm._v(" mdi-email-send-outline ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[(_vm.userCanDelete)?_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingDelete,"loading":item.loadingDelete,"color":"red","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.userCanManage)?_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingDownload,"loading":item.loadingDownload,"color":"teal","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.downloadPDF(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e(),(_vm.userCanAccess)?_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingView,"loading":item.loadingView,"color":"primary","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewItem(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.userCanManage)?_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingEdit,"loading":item.loadingEdit,"color":"grey","depressed":"","fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}])})],1)],1)],1),(_vm.userCanAdd || _vm.userCanManage)?_c('edit-dialog',{attrs:{"item":_vm.selectedItem,"visible":_vm.editDialogVisible},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"saveSuccess":_vm.getData}}):_vm._e(),(_vm.userCan('Offers', 'ACCESS'))?_c('offer-dialog',{attrs:{"item":_vm.selectedOffer,"visible":_vm.offerDialogVisible},on:{"update:visible":function($event){_vm.offerDialogVisible=$event}}}):_vm._e(),(_vm.userCanDelete || _vm.userCanManage)?_c('confirm-dialog',{ref:"confirm"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }