export default {

  Title: 'Εργασίες',

  Table: {
    Headers: {
      Id             : '#',
      OfferId        : 'ΠΡΟΣΦΟΡΑ',
      Firstname      : 'ΟΝΟΜΑ',
      Lastname       : 'ΕΠΩΝΥΜΟ',
      Phone          : 'ΤΗΛΕΦΩΝΟ',
      CustomerEmailAt: 'ΑΠΟΣΤΟΛΗ',
      Email          : 'EMAIL',
      Car            : 'ΟΧΗΜΑ',
      Plate          : 'ΑΡ.ΚΥΚΛ.',
      OrderedAt      : 'ΗΜ/ΝΙΑ',
      Actions        : ''
    }
  },

  Form: {
    Title : 'Εργασίας',
    Fields: {
      SearchOffer: {
        Label: 'Συνδεδεμένη Προσφορά',
        Hint : 'Αναζήτηση Προσφοράς'
      },
      OrderedAt: {
        Label: 'Ημερομηνία Εργασιών',
        Hint : 'DD/MM/YYYY'
      },
      InChargePerson: {
        Label: 'Υπέθυνος',
        Hint : ''
      },
      Customer: {
        Title    : 'Στοιχεία Πελάτη',
        Firstname: {
          Label: 'Όνομα',
          Hint : ''
        },
        Lastname: {
          Label: 'Επώνυμο',
          Hint : ''
        },
        Phone: {
          Label: 'Τηλέφωνο',
          Hint : ''
        },
        Email: {
          Label: 'Email',
          Hint : ''
        }
      },
      Car: {
        Title: 'Στοιχεία Οχήματος',
        Car  : {
          Label: 'Όχημα',
          Hint : ''
        },
        Plate: {
          Label: 'Αρ.Κυκλοφορίας',
          Hint : ''
        },
        Mileage: {
          Label: 'Χιλιόμετρα',
          Hint : ''
        }
      },
      ChangeTyres: {
        Label: 'Αλλαγή Ελαστικών',
        Hint : ''
      },
      RepairTyres: {
        Label: 'Επισκευή Ελαστικών',
        Hint : ''
      },
      Tyres: {
        Title   : 'Εργασίες Ελαστικών',
        SubTitle: 'Ελαστικά',
        Pressure: 'Πίεση Ελλαστικών',
        Brand   : {
          Label: 'Μάρκα',
          Hint : ''
        },
        Tread: {
          Label: 'Πέλμα',
          Hint : ''
        },
        Dimension: {
          Label: 'Διάσταση',
          Hint : ''
        },
        Notes: {
          Label: 'Παρατηρήσεις',
          Hint : ''
        },
        TyreFrontLeft: {
          Label: 'Εμπρός Αριστερά',
          Hint : ''
        },
        TyreFrontRight: {
          Label: 'Εμπρός Δεξιά',
          Hint : ''
        },
        TyreBackLeft: {
          Label: 'Πίσω Αριστερά',
          Hint : ''
        },
        TyreBackRight: {
          Label: 'Πίσω Δεξιά',
          Hint : ''
        },
        PressureFront: {
          Label: 'Πίεση Εμπρός',
          Hint : ''
        },
        PressureBack: {
          Label: 'Πίεση Πίσω',
          Hint : ''
        },
        PressureSpare: {
          Label: 'Πίεση Ρεζέρβας',
          Hint : ''
        },
        Description: {
          Label: 'Περιγραφή',
          Hint : ''
        }
      },
      CheckRims: {
        Title        : 'Έλεγχος Ζαντών',
        TyreFrontLeft: {
          Label: 'Εμπρός Αριστερά',
          Hint : ''
        },
        TyreFrontRight: {
          Label: 'Εμπρός Δεξιά',
          Hint : ''
        },
        TyreBackLeft: {
          Label: 'Πίσω Αριστερά',
          Hint : ''
        },
        TyreBackRight: {
          Label: 'Πίσω Δεξιά',
          Hint : ''
        },
        Notes: {
          Label: 'Παρατηρήσεις',
          Hint : ''
        }
      },
      Balancing: {
        Title: 'Ζυγοστάθμιση',
        Front: {
          Label: 'Εμπρός',
          Hint : ''
        },
        Back: {
          Label: 'Πίσω',
          Hint : ''
        },
        Notes: {
          Label: 'Παρατηρήσεις',
          Hint : ''
        }
      },
      AdditionalWorks: {
        Title       : 'Άλλες Εργασίες',
        ChangeValves: {
          Label: 'Αλλαγή Βαλβίδων',
          Hint : ''
        },
        Alignment: {
          Label: 'Ευθυγράμμιση',
          Hint : ''
        },
        CheckSpareWheel: {
          Label: 'Έλεγχος Ρεζέρβας',
          Hint : ''
        },
        ShockAbsorber: {
          Label: 'Αμορτισέρ',
          Hint : ''
        },
        Breaks: {
          Label: 'Φρένα',
          Hint : ''
        },
        Notes: {
          Label: 'Παρατηρήσεις',
          Hint : ''
        }
      },
      Marketing: {
        Title   : 'Ενέργειες Marketing',
        Tyrelife: {
          Label: 'Tyrelife',
          Hint : ''
        },
        AB: {
          Label: 'AB',
          Hint : ''
        },
        Shell: {
          Label: 'Shell',
          Hint : ''
        },
        Cosmote: {
          Label: 'Cosmote',
          Hint : ''
        },
        Notes: {
          Label: 'Παρατηρήσεις',
          Hint : ''
        }
      }
    },
    Filters: {
      DateRange: 'Ημ/νία Εργασίας'
    }
  },

  Button: {
    New     : 'ΝΕΑ ΕΡΓΑΣΙΑ',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  },

  ConfirmDialog: {
    Title   : 'Διαγραφή Εργασίας',
    SubTitle: 'Εργασία',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να διαγράψετε την εργασία;</div><br>
      <div class="font-weight-regular text-left">Η διαδικασία είναι <span class="font-weight-bold">ΜΗ</span> αναστρέψιμη και προχωρώντας η εργασία Θα διαγραφεί <span class="font-weight-bold">ΟΡΙΣΤΙΚΑ</span>!</div><br>
      <div class="font-weight-regular error--text text-left">Η διαγραφή εργασίας <span class="font-weight-bold">ΔΕΝ</span> μπορεί να αντιστραφεί.</div>
    `
  }

}
