<script>
import { HorizontalBar  } from 'vue-chartjs'

export default {
  name   : 'ReviewChart',
  extends: HorizontalBar,
  props  : {
    data: {
      type   : Object,
      default: null
    },
    options: {
      type   : Object,
      default: null
    }
  },
  mounted () {
    const chart = this.$data._chart
    if (chart) chart.destroy()

    this.$nextTick(() => {
      this.$nextTick(() => {
        this.renderChart(this.data, this.options)
      })
    })
  }
}
</script>
