<template>
  <v-container
    v-if="viewData"
    fluid
  />
</template>

<script>
import Auth              from '../../mixins/auth'
import Dashboard         from '../../api/mixins/Dashboard'
import AutoUpdateApiData from '../../mixins/autoUpdateApiData'

export default {
  name      : 'DashboardAdmin',
  components: {},
  directives: {},
  mixins    : [Auth, Dashboard, AutoUpdateApiData],
  props     : [],
  data () {
    return {}
  },
  computed: {
    viewData () {
      return this.$DataStore.Dashboard
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    // this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      if (this.userCan('Dashboard', 'ACCESS')) {
        this.get()
          .then(response => {
            if (response && response.status === 200 && response.data) {
              this.$DataStore.Dashboard = response.data
            }
          })
          .catch(e => { })
          .finally(() => { })
      }
    }
  }
}
</script>

<style scoped>

</style>
