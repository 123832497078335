<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>

export default {
  name: 'App',
  metaInfo () {
    return {
      title        : this.routeTitle,
      titleTemplate: (title) => {
        return title ? `${title} | ${this.appTitle}` : this.appTitle
      }
    }
  },
  directives: {},
  components: {},
  mixins    : [],
  data      : () => ({
    //
  }),
  computed: {
    appTitle () {
      return this.$t('Common.App.Title')
    },
    routeTitle () {
      const key = `Route.${this.$route.name}.Title`
      return this.$te(key) ? this.$t(key) : ''
    },
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  watch  : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {}
}
</script>

<style>
::-webkit-scrollbar {
  width  : 10px;
  height : 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow    : inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius : 4px;
  border-radius         : 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius : 4px;
  border-radius         : 4px;
  background            : rgb(220, 220, 220);
  -webkit-box-shadow    : inset 0 0 1px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:window-inactive {
  background : rgb(220, 220, 220);
}

::-webkit-scrollbar-track {
  /*margin : 20px 20px;*/
}
</style>
