import Vue       from 'vue'
import Router    from 'vue-router'
// Views
import Login     from './views/Login'
import Dashboard from './views/dashboard/Dashboard'
import Dealers   from './views/dealers/Dealers'
import Settings  from './views/settings/Settings'
import Users     from './views/users/Users'
import Offers    from './views/offers/Offers'
import Orders    from './views/orders/Orders'
import Reviews   from './views/reviews/Reviews'
// import PlaceHolder from './views/PlaceHolder'

Vue.use(Router)

const routes = [
  {
    name     : 'Login',
    path     : '/login',
    component: Login,
    meta     : {
      requiresAuth: false,
      layout      : 'login'
    }
  },

  {
    name     : 'Dashboard',
    path     : '/',
    component: Dashboard,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Dealers',
    path     : '/dealers',
    component: Dealers,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Users',
    path     : '/users',
    component: Users,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Offers',
    path     : '/offers',
    component: Offers,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Orders',
    path     : '/orders',
    component: Orders,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Reviews',
    path     : '/reviews',
    component: Reviews,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Settings',
    path     : '/settings',
    component: Settings,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name    : 'Default',
    path    : '*',
    redirect: '/login'
  }
]

if (window.location.href.endsWith('//')) {
  window.location.href = window.location.href.replace(/\/+$/, '')
}

const createRouter = () => new Router({
  mode  : 'history',
  base  : process.env.BASE_URL,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

const router = createRouter()

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch(error => error)
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  window.isVueRoutesInited = false
}

export default router
