import { API, APICall } from '../Api'

export default {
  name: 'Users',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.User.Get.Type,
        Endpoint: APICall.User.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.User.Save.Type,
        Endpoint: APICall.User.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.User.List.Type,
        Endpoint: APICall.User.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allDealers (data) {
      const Request = {
        Type    : APICall.Dealer.List.Type,
        Endpoint: APICall.Dealer.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async dealerFilters (data) {
      const Request = {
        Type    : APICall.Dealer.Filters.Type,
        Endpoint: APICall.Dealer.Filters.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }

  }

}
