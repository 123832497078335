export default {

  Login: {
    Title: '@:Auth.Login.Title',
    Icon : 'mdi-logout',
    Color: 'cyan',
    Desc : ''
  },

  Dashboard: {
    Title: '@:Dashboard.Title',
    Icon : 'mdi-home',
    Color: 'light-green',
    Desc : ''
  },

  Dealers: {
    Title: '@:Dealers.Title',
    Icon : 'mdi-briefcase-account',
    Color: 'blue',
    Desc : ''
  },

  Users: {
    Title: '@:Users.Title',
    Icon : 'mdi-account-supervisor-circle',
    Color: 'indigo',
    Desc : ''
  },

  Offers: {
    Title: '@:Offers.Title',
    Icon : 'mdi-tag-text-outline',
    Color: 'blue',
    Desc : ''
  },

  Orders: {
    Title: '@:Orders.Title',
    Icon : 'mdi-cart-outline',
    Color: 'green',
    Desc : ''
  },

  Reviews: {
    Title: '@:Reviews.Title',
    Icon : 'mdi-star-half-full',
    Color: 'orange',
    Desc : ''
  },

  Settings: {
    Title: '@:Settings.Title',
    Icon : 'mdi-tune',
    Color: 'blue-grey',
    Desc : ''
  }

}
