export default {

  Title: 'Αξιολογήσεις',

  Table: {
    Headers: {
      Id       : '#',
      Q1       : 'ΕΡΩΤΗΣΗ 1',
      Q2       : 'ΕΡΩΤΗΣΗ 2',
      Q3       : 'ΕΡΩΤΗΣΗ 3',
      Q4       : 'ΕΡΩΤΗΣΗ 4',
      Q5       : 'ΕΡΩΤΗΣΗ 5',
      Q6       : 'ΕΡΩΤΗΣΗ 6',
      Q7       : 'ΕΡΩΤΗΣΗ 7',
      Q8       : 'ΕΡΩΤΗΣΗ 8',
      CreatedAt: 'ΗΜ/ΝΙΑ',
      Actions  : ''
    }
  },

  Form: {
    Title   : 'Αξιολόγησης',
    SubTitle: 'Καταχωρήθηκε στις',
    Fields  : {
      Q1: {
        Number  : '1',
        Question: 'Πόσο ικανοποιημένοι είστε από την εξυπηρέτησή σας από τον πωλητή της εταιρείας μας;'
      },
      Q2: {
        Number  : '2',
        Question: 'Πόσο ικανοποιημένοι είστε από τις λύσεις που προτείναμε για τις ανάγκες σας;'
      },
      Q3: {
        Number  : '3',
        Question: 'Πόσο ικανοποιητικές ήταν οι τιμές και οι προσφορές μας;'
      },
      Q4: {
        Number  : '4',
        Question: 'Πόσο ικανοποιημένοι είστε από την ποικιλία των προϊόντων μας;'
      },
      Q5: {
        Number  : '5',
        Question: 'Πόσο ικανοποιημένοι είστε από το τελικό προϊόν που παραλάβατε;'
      },
      Q6: {
        Number  : '6',
        Question: 'Πώς θα εκτιμούσατε τη συνολική ικανοποίησή σας από τη συνεργασία με την εταιρεία μας;'
      },
      Q7: {
        Number  : '7',
        Question: 'Θα συστήνατε την εταιρεία μας και σε άλλους για τη φροντίδα του αυτοκινήτου τους;'
      },
      Q8: {
        Number  : '8',
        Question: 'Παρακαλούμε σημειώστε οποιαδήποτε πρόταση θα μπορούσε να μας βοηθήσει να σας εξυπηρετούμε καλύτερα.'
      }
    },
    Filters: {}
  },

  Button: {
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  }

}
