<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      max-width="1024px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <data-card
        v-if="editItem"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)} ${editItem.Id ? '#' + editItem.Id : ''}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    v-model="editItem.OfferId"
                    :items="searchOfferData.data"
                    :label="$t(`${parentComponentName}.Form.Fields.SearchOffer.Label`)"
                    :loading="searchOfferData.loading"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.SearchOffer.Hint`)"
                    :prepend-icon="$t(`Route.Offers.Icon`)"
                    :search-input.sync="searchOffer"
                    :readonly="isReadOnly"
                    :clearable="!isReadOnly"
                    item-value="Id"
                    no-filter
                    eager
                  >
                    <template v-slot:item="{item}">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.Firstname }} {{ item.Lastname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.Car }} - {{ item.Plate }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="caption">
                          {{ item.Phone }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        label
                      >
                        #{{ data.item.Id }} - {{ data.item.Firstname }} {{ data.item.Lastname }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  offset-md="2"
                  md="5"
                >
                  <v-text-field
                    v-model="editItem.OrderedAt"
                    v-mask="'##/##/####'"
                    :error-messages="serverErrorMessages['OrderedAt']"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.OrderedAt.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.OrderedAt.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('OrderedAt')"
                    type="tel"
                    :clearable="!isReadOnly"
                  />
                </v-col>

                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Customer.Title`) }}
                  </v-alert>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Firstname"
                    :error-messages="serverErrorMessages['Firstname']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Firstname.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Firstname.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Firstname')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Lastname"
                    :error-messages="serverErrorMessages['Lastname']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Lastname.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Lastname.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Lastname')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Phone"
                    :error-messages="serverErrorMessages['Phone']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Phone.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Phone.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Phone')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Email"
                    :error-messages="serverErrorMessages['Email']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Email.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Email.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Email')"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Car.Title`) }}
                  </v-alert>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Car"
                    :error-messages="serverErrorMessages['Car']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Car.Car.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Car.Car.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Car')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Plate"
                    :error-messages="serverErrorMessages['Plate']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Car.Plate.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Car.Plate.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Plate')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Mileage"
                    :error-messages="serverErrorMessages['Mileage']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Car.Mileage.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Car.Mileage.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Mileage')"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    dense
                    light
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Tyres.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.ChangeTyres"
                    :error-messages="serverErrorMessages['ChangeTyres']"
                    :hint="$t(`${parentComponentName}.Form.Fields.ChangeTyres.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.ChangeTyres.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('ChangeTyres')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                    @change="onChangeTyresChange()"
                  />
                  <v-switch
                    v-model="editItem.RepairTyres"
                    :error-messages="serverErrorMessages['RepairTyres']"
                    :hint="$t(`${parentComponentName}.Form.Fields.RepairTyres.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.RepairTyres.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('RepairTyres')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                    @change="onRepairTyresChange()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    text
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Tyres.SubTitle`) }}
                  </v-alert>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Tyres.Brand"
                    :error-messages="serverErrorMessages['Tyres.Brand']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Brand.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.Brand.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.Brand')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Tyres.Tread"
                    :error-messages="serverErrorMessages['Tyres.Tread']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Tread.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.Tread.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.Tread')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Tyres.Dimension"
                    :error-messages="serverErrorMessages['Tyres.Dimension']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Dimension.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.Dimension.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.Dimension')"
                  />
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.Tyres.TyreFrontLeft"
                    :error-messages="serverErrorMessages['Tyres.TyreFrontLeft']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.TyreFrontLeft.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.TyreFrontLeft.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.TyreFrontLeft')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Tyres.TyreFrontRight"
                    :error-messages="serverErrorMessages['Tyres.TyreFrontRight']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.TyreFrontRight.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.TyreFrontRight.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.TyreFrontRight')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Tyres.TyreBackLeft"
                    :error-messages="serverErrorMessages['Tyres.TyreBackLeft']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.TyreBackLeft.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.TyreBackLeft.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.TyreBackLeft')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Tyres.TyreBackRight"
                    :error-messages="serverErrorMessages['Tyres.TyreBackRight']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.TyreBackRight.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.TyreBackRight.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.TyreBackRight')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.Tyres.Notes"
                    :error-messages="serverErrorMessages['Tyres.Notes']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Notes.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.Notes.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.Notes')"
                    auto-grow
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    text
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Tyres.Pressure`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.Tyres.PressureFront"
                    :error-messages="serverErrorMessages['Tyres.PressureFront']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.PressureFront.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.PressureFront.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.PressureFront')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Tyres.PressureBack"
                    :error-messages="serverErrorMessages['Tyres.PressureBack']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.PressureBack.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.PressureBack.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.PressureBack')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Tyres.PressureSpare"
                    :error-messages="serverErrorMessages['Tyres.PressureSpare']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.PressureSpare.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.PressureSpare.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.PressureSpare')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.Tyres.Description"
                    :error-messages="serverErrorMessages['Tyres.Description']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Description.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Tyres.Description.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Tyres.Description')"
                    auto-grow
                    filled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.CheckRims.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.CheckRims.TyreFrontLeft"
                    :error-messages="serverErrorMessages['CheckRims.TyreFrontLeft']"
                    :hint="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreFrontLeft.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreFrontLeft.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('CheckRims.TyreFrontLeft')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.CheckRims.TyreFrontRight"
                    :error-messages="serverErrorMessages['CheckRims.TyreFrontRight']"
                    :hint="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreFrontRight.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreFrontRight.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('CheckRims.TyreFrontRight')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.CheckRims.TyreBackLeft"
                    :error-messages="serverErrorMessages['CheckRims.TyreBackLeft']"
                    :hint="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreBackLeft.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreBackLeft.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('CheckRims.TyreBackLeft')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.CheckRims.TyreBackRight"
                    :error-messages="serverErrorMessages['CheckRims.TyreBackRight']"
                    :hint="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreBackRight.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.CheckRims.TyreBackRight.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('CheckRims.TyreBackRight')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.CheckRims.Notes"
                    :error-messages="serverErrorMessages['CheckRims.Notes']"
                    :hint="$t(`${parentComponentName}.Form.Fields.CheckRims.Notes.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.CheckRims.Notes.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('CheckRims.Notes')"
                    auto-grow
                    filled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Balancing.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.Balancing.Front"
                    :error-messages="serverErrorMessages['Balancing.Front']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Balancing.Front.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Balancing.Front.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Balancing.Front')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Balancing.Back"
                    :error-messages="serverErrorMessages['Balancing.Back']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Balancing.Back.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Balancing.Back.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Balancing.Back')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.Balancing.Notes"
                    :error-messages="serverErrorMessages['Balancing.Notes']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Balancing.Notes.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Balancing.Notes.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Balancing.Notes')"
                    auto-grow
                    filled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.AdditionalWorks.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.AdditionalWorks.ChangeValves"
                    :error-messages="serverErrorMessages['AdditionalWorks.ChangeValves']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.ChangeValves.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.ChangeValves.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.ChangeValves')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.AdditionalWorks.CheckSpareWheel"
                    :error-messages="serverErrorMessages['AdditionalWorks.CheckSpareWheel']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.CheckSpareWheel.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.CheckSpareWheel.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.CheckSpareWheel')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.AdditionalWorks.Breaks"
                    :error-messages="serverErrorMessages['AdditionalWorks.Breaks']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Breaks.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Breaks.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.Breaks')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.AdditionalWorks.Alignment"
                    :error-messages="serverErrorMessages['AdditionalWorks.Alignment']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Alignment.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Alignment.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.Alignment')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.AdditionalWorks.ShockAbsorber"
                    :error-messages="serverErrorMessages['AdditionalWorks.ShockAbsorber']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.ShockAbsorber.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.ShockAbsorber.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.ShockAbsorber')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.AdditionalWorks.Notes"
                    :error-messages="serverErrorMessages['AdditionalWorks.Notes']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Notes.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Notes.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.Notes')"
                    auto-grow
                    filled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Marketing.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.Marketing.Tyrelife"
                    :error-messages="serverErrorMessages['Marketing.Tyrelife']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Marketing.Tyrelife.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Marketing.Tyrelife.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Marketing.Tyrelife')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Marketing.AB"
                    :error-messages="serverErrorMessages['Marketing.AB']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Marketing.AB.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Marketing.AB.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Marketing.AB')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Marketing.Shell"
                    :error-messages="serverErrorMessages['Marketing.Shell']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Marketing.Shell.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Marketing.Shell.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Marketing.Shell')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                  <v-switch
                    v-model="editItem.Marketing.Cosmote"
                    :error-messages="serverErrorMessages['Marketing.Cosmote']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Marketing.Cosmote.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Marketing.Cosmote.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Marketing.Cosmote')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.AdditionalWorks.Notes"
                    :error-messages="serverErrorMessages['AdditionalWorks.Notes']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Notes.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Notes.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.Notes')"
                    auto-grow
                    filled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  offset-md="7"
                  md="5"
                >
                  <v-text-field
                    v-model="editItem.InChargePerson"
                    :error-messages="serverErrorMessages['InChargePerson']"
                    :hint="$t(`${parentComponentName}.Form.Fields.InChargePerson.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.InChargePerson.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('InChargePerson')"
                    class=""
                    :clearable="!isReadOnly"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>

          <v-divider />

          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isSaving"
              :loading="isSaving"
              color="primary"
              depressed
              @click="saveItem(false)"
              v-text="editItem.Id ? $t('Common.Button.Save') : $t('Common.Button.Create')"
            />

            <v-btn
              v-if="!isReadOnly"
              :disabled="isSaving"
              :loading="isSaving"
              color="green"
              depressed
              dark
              @click="saveItem(true)"
              v-text="editItem.Id ? $t('Common.Button.SaveAndSend') : $t('Common.Button.CreateAndSend')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Orders        from '../../api/mixins/Orders'
import OrderModel    from '../../api/mixins/models/OrderModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'OrderEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Orders, OrderModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Orders',
      searchOffer        : '',
      searchOfferData    : {
        data   : [],
        total  : 0,
        loading: false
      }
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch   : {
    searchOffer (newVal, oldVal) {
      if (this.editItem.OfferId && this.editItem.Offer) {
        this.searchOfferData.total = 1
        this.searchOfferData.data = [this.editItem.Offer]
      } else {
        this.searchOfferData.total = 0
        this.searchOfferData.data = []
      }
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 3) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchOffers(newVal) }, 500)
    },
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (!this.editItem.Id) this.editItem.InChargePerson = this.user.Fullname || ''
          if (this.editItem.OfferId && this.editItem.Offer) {
            this.searchOfferData.total = 1
            this.searchOfferData.data = [this.editItem.Offer]
          } else {
            this.searchOfferData.total = 0
            this.searchOfferData.data = []
          }
        })
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    searchOffers (search) {
      if (!search) return
      this.searchOfferData.loading = true
      this.offerSearch({
        itemsPerPage: -1,
        page        : 1,
        sortBy      : 'Lastname',
        orderBy     : 'ASC',
        search      : search
      }).then(response => {
        if (response && response.status === 200 && response.data && !response.data.error && response.data.status !== 'failed') {
          this.searchOfferData.total = response.data.total || 0
          this.searchOfferData.data = response.data.data || []
        } else {
          if (response.data.status === 'failed') {
            this.setServerErrorMessages(response.data.data)
          } else if (response.data.error) {
            this.setServerErrorMessages({ OfferId: 'Δεν βρέθηκε προσφορά' })
          }
        }
      }).catch(e => {
        this.searchOfferData.total = 0
        this.searchOfferData.data = []
      }).finally(() => {
        this.searchOfferData.loading = false
      })
    },

    saveItem (SendCustomerEmail) {
      this.formValidate()

      if (!this.formValidate()) return
      this.isSaving = true

      this.editItem.SendCustomerEmail = SendCustomerEmail
      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    onRepairTyresChange () {
      if (this.editItem.ChangeTyres) this.editItem.ChangeTyres = !this.editItem.RepairTyres
    },

    onChangeTyresChange () {
      if (this.editItem.RepairTyres) this.editItem.RepairTyres = !this.editItem.ChangeTyres
    }
  }
}
</script>

<style scoped>

</style>
