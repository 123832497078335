export default {
  name: 'OrderModel',

  data () {
    return {
      model: {
        Id         : null,
        OfferId    : null,
        Firstname  : '',
        Lastname   : '',
        Phone      : '',
        Email      : '',
        Car        : '',
        Plate      : '',
        Mileage    : '',
        ChangeTyres: false,
        RepairTyres: false,
        Tyres      : {
          Brand         : '',
          Tread         : '',
          Dimension     : '',
          Notes         : '',
          Description   : '',
          TyreFrontLeft : false,
          TyreFrontRight: false,
          TyreBackLeft  : false,
          TyreBackRight : false,
          PressureFront : false,
          PressureBack  : false,
          PressureSpare : false
        },
        CheckRims: {
          TyreFrontLeft : false,
          TyreFrontRight: false,
          TyreBackLeft  : false,
          TyreBackRight : false,
          Notes         : ''
        },
        Balancing: {
          Front: false,
          Back : false,
          Notes: ''
        },
        AdditionalWorks: {
          ChangeValves   : false,
          CheckSpareWheel: false,
          Breaks         : false,
          Alignment      : false,
          ShockAbsorber  : false,
          Notes          : ''
        },
        Marketing: {
          Tyrelife: false,
          AB      : false,
          Shell   : false,
          Cosmote : false,
          Notes   : ''
        },
        InChargePerson   : '',
        OrderedAt        : initDate(),
        CustomerEmailAt  : '',
        SendCustomerEmail: false
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        OfferId: {
          required: false,
          type    : 'integer'
        },
        Firstname: {
          required: true,
          minLen  : 2,
          maxLen  : 255,
          type    : 'string'
        },
        Lastname: {
          required: true,
          minLen  : 2,
          maxLen  : 255,
          type    : 'string'
        },
        Email: {
          required: false,
          type    : 'email'
        },
        Phone: {
          required: false,
          length  : 10,
          type    : 'string'
        },
        OrderedAt: {
          required: true,
          type    : 'dateStringSlash'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}

const initDate = () => {
  const d = new Date()
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [day, month, year].join('/')
}
