import { i18n }        from '../../../lang/lang'
import { pathToValue } from '../../utils/object'
import * as Validators from './Validators'

export default class BaseValidator {
  #model = null
  #modelErrors = {}
  #errorMessages = {
    el: {
      type: {
        alpha              : 'Πρέπει να είναι μόνο γράμματα',
        alphaNum           : 'Πρέπει να είναι αλφαριθμητικό',
        array              : 'Πρέπει να είναι σειρά αντικειμένων [{rule}]',
        object             : 'Πρέπει να είναι αντικείμενο [{rule}]',
        function           : 'Πρέπει να είναι λειτουργία [{rule}]',
        promise            : 'Πρέπει να είναι [{rule}]',
        boolean            : 'Πρέπει να είναι [{rule}]',
        number             : 'Πρέπει να είναι έγκυρος αριθμός',
        integer            : 'Πρέπει να είναι έγκυρος ακέραιος',
        float              : 'Πρέπει να είναι έγκυρος δεκαδικός',
        string             : 'Πρέπει να είναι κείμενο',
        url                : 'Πρέπει να είναι έγκυρο {rule}',
        domain             : 'Πρέπει να είναι έγκυρο {rule}',
        email              : 'Πρέπει να είναι έγκυρο {rule}',
        date               : 'Πρέπει να είναι έγκυρη ημερομηνία',
        mysqlDateTime      : 'Πρέπει να είναι έγκυρη ημερομηνία και ώρα της μορφής YYYY-MM-DD HH:mm:ss',
        mysqlDateOrDateTime: 'Πρέπει να είναι έγκυρη ημερομηνία ή ημερομηνία και ώρα της μορφής YYYY-MM-DD HH:mm:ss',
        mysqlDate          : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής YYYY-MM-DD',
        timeString         : 'Πρέπει να είναι έγκυρη ώρα της μορφής HH:mm:ss ή HH:mm',
        dateStringDash     : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής DD-MM-YYYY',
        dateStringSlash    : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής DD/MM/YYYY',
        greekMobilePhone   : 'Πρέπει να είναι έγκυρος αριθμός κινητού τηλεφώνου',
        greekLandlinePhone : 'Πρέπει να είναι έγκυρος αριθμός σταθερού τηλεφώνου',
        undefined          : 'Πρέπει να είναι [{rule}]'
      },
      required  : 'Το πεδίο είναι υποχρεωτικό',
      requiredIf: 'Το πεδίο είναι υποχρεωτικό',
      regexp    : 'Μη αποδεκτή τιμή',
      min       : 'Πρέπει να είναι από {rule}',
      max       : 'Πρέπει να είναι έως {rule}',
      between   : 'Πρέπει να είναι από {rule} έως {rule}',
      minLen    : 'Πρέπει να είναι από {rule} χαρακτήρες',
      maxLen    : 'Πρέπει να είναι έως {rule} χαρακτήρες',
      betweenLen: 'Πρέπει να είναι από {rule} έως {rule}',
      length    : 'Πρέπει να είναι {rule} χαρακτήρες',
      equals    : 'Πρέπει να είναι ίδιο με το πεδίο {rule}',
      is        : 'Πρέπει να είναι {rule}',
      isNot     : 'Δεν πρέπει να είναι {rule}',
      isIn      : 'Πρέπει να είναι ένα από {rule}',
      dateFormat: 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής {rule}',
      timeFormat: 'Πρέπει να είναι έγκυρη ώρα της μορφής {rule}',
      undefined : 'Γενικό λάθος'
    },
    en: {
      type: {
        alpha              : 'Must contain only letters',
        alphaNum           : 'Must be alphanumeric',
        array              : 'Must be a valid {rule}',
        object             : 'Must be a valid {rule}',
        function           : 'Must be a valid {rule}',
        promise            : 'Must be a valid {rule}',
        boolean            : 'Must be a valid {rule}',
        number             : 'Must be a valid {rule}',
        integer            : 'Must be a valid {rule}',
        float              : 'Must be a valid {rule}',
        string             : 'Must be a valid {rule}',
        url                : 'Must be a valid {rule}',
        domain             : 'Must be a valid {rule}',
        email              : 'Must be a valid {rule}',
        date               : 'Must be a valid {rule}',
        mysqlDateTime      : 'Must be a valid date and time formatted as YYYY-MM-DD HH:mm:ss',
        mysqlDateOrDateTime: 'Must be a valid date or date and time formatted as YYYY-MM-DD HH:mm:ss',
        mysqlDate          : 'Must be a valid date formatted as YYYY-MM-DD',
        timeString         : 'Must be a valid time formatted as HH:mm:ss or HH:mm',
        dateStringDash     : 'Must be a valid date formatted as DD-MM-YYYY',
        dateStringSlash    : 'Must be a valid date formatted as DD/MM/YYYY',
        greekMobilePhone   : 'Must be a valid mobile phone number',
        greekLandlinePhone : 'Must be a valid land line phone number',
        undefined          : 'This is not a valid [{rule}]'
      },
      required  : 'This field is required',
      requiredIf: 'This field is required',
      regexp    : 'This is not a valid value',
      min       : 'Minimum value is {rule}',
      max       : 'Maximum value is {rule}',
      between   : 'Must be Between {rule} and {rule}',
      minLen    : 'Minimum length is {rule}',
      maxLen    : 'Maximum length is {rule}',
      betweenLen: 'Length must be Between {rule} and {rule}',
      length    : 'Length must be {rule}',
      equals    : 'Must be equal to the field {rule}',
      is        : 'Must be {rule}',
      isNot     : 'Must not be {rule}',
      isIn      : 'Must be one of {rule}',
      dateFormat: 'Must be a valid date formatted as {rule}',
      timeFormat: 'Must be a valid time formatted as {rule}',
      undefined : 'General error'
    }
  }

  rules = {}
  errorMessages = {
    el: {},
    en: {}
  }

  /**
   *
   * @param {Object | BaseModel} [rawData={}] the object or model to validate.
   * @return {BaseValidator} A BaseValidation object.
   */
  constructor (rawData = {}, validationRules, validationMessages) {
    this.#model = rawData

    if (validationRules) this.rules = validationRules
    if (validationMessages) this.errorMessages = validationMessages
  }

  /* PROPERTIES */
  get messages () {
    return Object.assign({}, this.#errorMessages[i18n.locale], this.errorMessages[i18n.locale])
  }

  get validationMessages () {
    return this.#modelErrors
  }

  /* METHODS */
  validate () {
    this.clearValidationMessages()

    Object.keys(this.#model).forEach(property => {
      if (property in this.rules) {
        this.runValidations(property)
      }
    })

    Object.keys(this.rules).filter(key => key.includes('.')).forEach(property => {
      this.runValidations(property)
    })

    return Object.keys(this.#modelErrors).length <= 0
  }

  validateField (property) {
    this.clearValidationMessages()

    if (property in this.rules) {
      this.runValidations(property)
    }

    return Object.keys(this.#modelErrors).length <= 0
  }

  runValidations (property) {
    const propertyRules = this.rules[property]
    if (propertyRules.hasOwnProperty('requiredIf')) propertyRules.required = Validators.requiredIf(this.rules[property].requiredIf, property.includes('.') ? pathToValue(property, this.#model) : this.#model[property], this.#model)
    const propertyIsRequired = propertyRules.hasOwnProperty('required') && propertyRules.required

    Object.keys(propertyRules).forEach(rule => {
      let ruleResult = true
      const propertyValue = property.includes('.') ? pathToValue(property, this.#model) : this.#model[property]
      const propertyRule = this.rules[property][rule]

      if (rule in Validators || typeof propertyRule === 'function') {
        if (rule === 'type') {
          if (propertyRule in Validators.types || typeof propertyRule === 'function') {
            ruleResult = Validators[rule](propertyRule, propertyValue)
          } else {
            // eslint-disable-next-line no-console
            console.warn(`VALIDATOR ::: ${property}:: Rule: ${rule} ${propertyRule} is not a valid rule!`)
          }
        } else if (rule === 'equals') {
          ruleResult = Validators[rule](propertyRule, propertyValue, this.#model)
        } else if (typeof propertyRule === 'function') {
          ruleResult = propertyRule(propertyValue)
        } else {
          ruleResult = Validators[rule](propertyRule, propertyValue)
        }
        if (!ruleResult && (propertyIsRequired || propertyValue)) {
          let errorMessage = null
          errorMessage = property.includes('.') ? pathToValue(property, this.#modelErrors) : this.#modelErrors[property]

          if (property.includes('.')) {
            let obj = this.#modelErrors
            const p = property.split('.')
            const lastKeyIndex = p.length - 1
            if (errorMessage) {
              errorMessage.push(this.getErrorMessage(rule, propertyRule))
            } else {
              for (var i = 0; i < lastKeyIndex; ++i) {
                const key = p[i]
                if (!(key in obj)) { obj[key] = {} }
                obj = obj[key]
              }
              obj[p[lastKeyIndex]] = [this.getErrorMessage(rule, propertyRule)]
            }
          } else {
            if (errorMessage) {
              errorMessage.push(this.getErrorMessage(rule, propertyRule))
            } else {
              errorMessage = [this.getErrorMessage(rule, propertyRule)]
              this.#modelErrors[property] = errorMessage
            }
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`VALIDATOR ::: Filed: ${property} - Rule: ${rule} is not a valid rule!`)
      }
    })
  }

  vuetifyFormRules () {
    const rulesObject = {}

    Object.keys(this.#model).forEach(property => {
      if (property in this.rules) {
        rulesObject[property] = this.vuetifyFormFieldRules(property)
      }
    })

    Object.keys(this.rules).filter(key => key.includes('.')).forEach(property => {
      let obj = rulesObject
      const p = property.split('.')
      const lastKeyIndex = p.length - 1

      for (var i = 0; i < lastKeyIndex; ++i) {
        const key = p[i]
        if (!(key in obj)) { obj[key] = {} }
        obj = obj[key]
      }
      obj[p[lastKeyIndex]] = this.vuetifyFormFieldRules(property)
    })

    return rulesObject
  }

  vuetifyFormFieldRules (property = '') {
    const rulesArray = []

    if (property in this.rules) {
      const propertyRules = this.rules[property]
      if (propertyRules.hasOwnProperty('requiredIf')) propertyRules.required = Validators.requiredIf(this.rules[property].requiredIf, property.includes('.') ? pathToValue(property, this.#model) : this.#model[property], this.#model)
      const propertyIsRequired = propertyRules.hasOwnProperty('required') && propertyRules.required

      Object.keys(propertyRules).forEach(rule => {
        const propertyValue = property.includes('.') ? pathToValue(property, this.#model) : this.#model[property]
        const propertyRule = this.rules[property][rule]

        if (rule in Validators || typeof propertyRule === 'function') {
          if (rule === 'type') {
            if (propertyRule in Validators.types) {
              rulesArray.push((v) => {
                if (propertyIsRequired || propertyValue) {
                  return Validators[rule](propertyRule, v) || this.getErrorMessage(rule, propertyRule)
                } else {
                  return true
                }
              })
            } else {
              // eslint-disable-next-line no-console
              console.warn(`VALIDATOR ::: Field: ${property} - Rule: ${rule} ${propertyRule} is not a valid rule!`)
            }
          } else if (rule === 'equals') {
            rulesArray.push((v) => {
              if (propertyIsRequired || propertyValue) {
                return Validators[rule](propertyRule, v, this.#model) || this.getErrorMessage(rule, propertyRule)
              } else {
                return true
              }
            })
          } else if (typeof propertyRule === 'function') {
            rulesArray.push((v) => {
              if (propertyIsRequired || propertyValue) {
                return propertyRule(v) || this.getErrorMessage(rule, propertyRule)
              } else {
                return true
              }
            })
          } else if (rule !== 'requiredIf') {
            rulesArray.push((v) => {
              if (propertyIsRequired || propertyValue) {
                return Validators[rule](propertyRule, v) || this.getErrorMessage(rule, propertyRule)
              } else {
                return true
              }
            })
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(`VALIDATOR ::: ${property}:: Rule: ${rule} is not a valid rule!`)
        }
      })
    }

    return rulesArray
  }

  clearValidationMessages () {
    this.#modelErrors = {}
  }

  getErrorMessage (ruleName, ruleValue) {
    let msg = ''
    if (ruleName in this.messages) {
      if (ruleName === 'type') {
        if (this.messages.type.hasOwnProperty(ruleValue) && this.messages.type[ruleValue] !== '') {
          msg = this.messages.type[ruleValue]
        } else {
          msg = this.messages.type.undefined
        }
      } else {
        msg = this.messages[ruleName]
      }
    } else {
      msg = this.messages.undefined
    }

    if (!Array.isArray(ruleValue)) ruleValue = [ruleValue]

    ruleValue.forEach(val => {
      if (ruleName === 'equals' && val.includes('|')) val = val.split('|')[1]
      msg = msg.replace('{rule}', val)
    })

    return msg
  }
}
