var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-4"},[(_vm.userCan('Offers', 'ACCESS'))?_c('list-card',{attrs:{"data":_vm.data.Data,"title":_vm.$t('Route.Offers.Title'),"icon":_vm.$t('Route.Offers.Icon'),"colored-border":false,"color":_vm.$t('Route.Offers.Color'),"avatar-icon":_vm.$t('Route.Offers.Icon'),"avatar-icon-color":"black","avatar-color":"grey lighten-2","list-height":"300","dark":"","dense":"","avatar":"","click-event":"item-click"},on:{"item-click":_vm.onItemClick},scopedSlots:_vm._u([{key:"toolbar-append",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":650,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: 'Offers'}}},on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)]}}],null,false,1782580560)},[_c('span',[_vm._v(_vm._s(_vm.$t('Common.Button.ViewAll')))])])]},proxy:true},{key:"list-item-content-prepend",fn:function(ref){return [_c('v-list-item-avatar',{staticClass:"align-center justify-center",attrs:{"color":_vm.$t('Route.Offers.Color')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$t('Route.Offers.Icon'))+" ")])],1)]}},{key:"list-item-content",fn:function(ref){
var item = ref.item;
var color = ref.color;
return [_c('v-list-item-content',[_c('v-list-item-title',{class:(color + "--text"),domProps:{"textContent":_vm._s(((item.Firstname) + " " + (item.Lastname)))}}),_c('v-list-item-subtitle',{staticClass:"text-no-wrap text--primary"},[_vm._v(" "+_vm._s(item.Car)+" "),(item.Car && item.Plate)?[_vm._v(" - ")]:_vm._e(),_vm._v(" "+_vm._s(item.Plate && item.Plate.toLocaleUpperCase(_vm.$i18n.locale))+" "),((item.Car || item.Plate) && item.Mileage)?[_vm._v(" - ")]:_vm._e(),(item.Mileage)?_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.Mileage)+" xλμ.")]):_vm._e()],2),_c('v-list-item-subtitle',{staticClass:"text-no-wrap caption"},[_vm._v(" "+_vm._s(item.Phone)+" "),(item.Phone && item.Email)?[_vm._v(" - ")]:_vm._e(),_vm._v(" "+_vm._s(item.Email)+" ")],2)],1)]}},{key:"list-item-content-append",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',{staticClass:"align-center"},[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.OfferedAt)}}),_c('div',[_c('v-btn',{attrs:{"icon":"","large":"","color":"teal"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPDF(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)],1)]}}],null,false,305321584)}):_vm._e(),_c('offer-details-dialog',{attrs:{"item":_vm.selectedItem,"visible":_vm.viewDialogVisible},on:{"update:visible":function($event){_vm.viewDialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }