<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      max-width="1024px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <data-card
        v-if="editItem"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)} ${editItem.Id ? '#' + editItem.Id : ''}`"
        :sub-title="editItem.OrderId ? `Συνδεδεμένη ${$t('Orders.ConfirmDialog.SubTitle')} #${editItem.OrderId}` : ``"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-row>
                <v-col
                  cols="12"
                  offset-md="7"
                  md="5"
                >
                  <v-text-field
                    v-model="editItem.OfferedAt"
                    v-mask="'##/##/####'"
                    :error-messages="serverErrorMessages['OfferedAt']"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.OfferedAt.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.OfferedAt.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('OfferedAt')"
                    type="tel"
                    :clearable="!isReadOnly"
                  />
                </v-col>

                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Customer.Title`) }}
                  </v-alert>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Firstname"
                    :error-messages="serverErrorMessages['Firstname']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Firstname.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Firstname.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Firstname')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Lastname"
                    :error-messages="serverErrorMessages['Lastname']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Lastname.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Lastname.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Lastname')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Phone"
                    :error-messages="serverErrorMessages['Phone']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Phone.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Phone.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Phone')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editItem.Email"
                    :error-messages="serverErrorMessages['Email']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Customer.Email.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Customer.Email.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Email')"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Car.Title`) }}
                  </v-alert>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Car"
                    :error-messages="serverErrorMessages['Car']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Car.Car.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Car.Car.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Car')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Plate"
                    :error-messages="serverErrorMessages['Plate']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Car.Plate.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Car.Plate.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Plate')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="editItem.Mileage"
                    :error-messages="serverErrorMessages['Mileage']"
                    :hint="$t(`${parentComponentName}.Form.Fields.Car.Mileage.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.Car.Mileage.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Mileage')"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    dense
                    light
                  >
                    <v-row align="center">
                      <v-col class="grow py-3">
                        {{ $t(`${parentComponentName}.Form.Fields.Tyres.Title`) }}
                      </v-col>
                      <v-col
                        v-if="!editItem.Id && !isReadOnly"
                        class="shrink py-0"
                      >
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          depressed
                          :disabled="editItem.Tyres && editItem.Tyres.length === 4"
                          fab
                          x-small
                          @click="addTyresProposal"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>

                <template v-for="(tyre, index) in editItem.Tyres">
                  <v-col
                    :key="`tyre-${index}`"
                    cols="12"
                    :md="editItem.Tyres.length > 1 ? 6 : 12"
                  >
                    <v-alert
                      border="left"
                      color="pyellow"
                      class="font-weight-bold"
                      dense
                      light
                      text
                    >
                      <v-row align="center">
                        <v-col class="grow py-3">
                          {{ $t(`${parentComponentName}.Form.Fields.Tyres.SubTitle`) }} #{{ index + 1 }}
                        </v-col>
                        <v-col
                          v-if="editItem.Tyres.length > 1 && !editItem.Id && !isReadOnly"
                          class="shrink py-0"
                        >
                          <v-btn
                            class="white--text"
                            color="grey darken-3"
                            depressed
                            fab
                            x-small
                            @click="removeTyresProposal(index)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="tyre.Brand"
                          :error-messages="serverErrorMessages['Tyres.Brand']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Brand.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Brand.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Brand')"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="tyre.Tread"
                          :error-messages="serverErrorMessages['Tyres.Tread']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Tread.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Tread.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Tread')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="tyre.Dimension"
                          :error-messages="serverErrorMessages['Tyres.Dimension']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Dimension.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Dimension.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Dimension')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="tyre.Price"
                          :error-messages="serverErrorMessages['Tyres.Price']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Price.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Price.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Price')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="tyre.Warranty"
                          :error-messages="serverErrorMessages['Tyres.Warranty']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Warranty.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Warranty.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Warranty')"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-alert
                          border="left"
                          color="grey"
                          class="font-weight-bold"
                          dense
                          light
                          text
                        >
                          {{ $t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Title`) }}
                        </v-alert>
                        <v-switch
                          v-model="tyre.Marketing.Tyrelife"
                          :error-messages="serverErrorMessages['Tyres.Marketing.Tyrelife']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Tyrelife.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Tyrelife.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Marketing.Tyrelife')"
                          class="d-inline-flex mr-6"
                          color="success"
                          inset
                        />
                        <v-switch
                          v-model="tyre.Marketing.AB"
                          :error-messages="serverErrorMessages['Tyres.Marketing.AB']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.AB.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.AB.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Marketing.AB')"
                          class="d-inline-flex mr-6"
                          color="success"
                          inset
                        />
                        <v-switch
                          v-model="tyre.Marketing.Shell"
                          :error-messages="serverErrorMessages['Tyres.Marketing.Shell']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Shell.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Shell.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Marketing.Shell')"
                          class="d-inline-flex mr-6"
                          color="success"
                          inset
                        />
                        <v-switch
                          v-model="tyre.Marketing.Cosmote"
                          :error-messages="serverErrorMessages['Tyres.Marketing.Cosmote']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Cosmote.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Cosmote.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Marketing.Cosmote')"
                          class="d-inline-flex mr-6"
                          color="success"
                          inset
                        />
                        <v-text-field
                          v-model="tyre.Marketing.Notes"
                          :error-messages="serverErrorMessages['Tyres.Marketing.Notes']"
                          :hint="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Notes.Hint`)"
                          :label="$t(`${parentComponentName}.Form.Fields.Tyres.Marketing.Notes.Label`)"
                          :readonly="isReadOnly"
                          :rules="validator.vuetifyFormFieldRules('Tyres.Marketing.Notes')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.AdditionalWorks.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="editItem.AdditionalWorks.ChangeValves"
                    :error-messages="serverErrorMessages['AdditionalWorks.ChangeValves']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.ChangeValves.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.ChangeValves.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.ChangeValves')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />

                  <v-switch
                    v-model="editItem.AdditionalWorks.Balancing"
                    :error-messages="serverErrorMessages['AdditionalWorks.Balancing']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Balancing.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.Balancing.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.Balancing')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />

                  <v-switch
                    v-model="editItem.AdditionalWorks.CheckSpareWheel"
                    :error-messages="serverErrorMessages['AdditionalWorks.CheckSpareWheel']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.CheckSpareWheel.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.CheckSpareWheel.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.CheckSpareWheel')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />

                  <v-switch
                    v-model="editItem.AdditionalWorks.CheckRims"
                    :error-messages="serverErrorMessages['AdditionalWorks.CheckRims']"
                    :hint="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.CheckRims.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.AdditionalWorks.CheckRims.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('AdditionalWorks.CheckRims')"
                    class="d-inline-flex mr-6"
                    color="success"
                    inset
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  style="height: 54px"
                >
                  <v-alert
                    border="left"
                    color="pyellow"
                    class="font-weight-bold"
                    light
                    dense
                  >
                    {{ $t(`${parentComponentName}.Form.Fields.Notes.Title`) }}
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="editItem.Notes"
                    :error-messages="serverErrorMessages['Notes']"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('Notes')"
                    auto-grow
                    filled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    v-model="editItem.ValidUntilAt"
                    v-mask="'##/##/####'"
                    :error-messages="serverErrorMessages['ValidUntilAt']"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.ValidUntilAt.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.ValidUntilAt.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('ValidUntilAt')"
                    type="tel"
                    class=""
                    :clearable="!isReadOnly"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                />
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    v-model="editItem.NextAppointmentAt"
                    v-mask="'##/##/####'"
                    :error-messages="serverErrorMessages['NextAppointmentAt']"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.NextAppointmentAt.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.NextAppointmentAt.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('NextAppointmentAt')"
                    type="tel"
                    class=""
                    :clearable="!isReadOnly"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  offset-md="7"
                  md="5"
                >
                  <v-text-field
                    v-model="editItem.InChargePerson"
                    :error-messages="serverErrorMessages['InChargePerson']"
                    :hint="$t(`${parentComponentName}.Form.Fields.InChargePerson.Hint`)"
                    :label="$t(`${parentComponentName}.Form.Fields.InChargePerson.Label`)"
                    :readonly="isReadOnly"
                    :rules="validator.vuetifyFormFieldRules('InChargePerson')"
                    class=""
                    :clearable="!isReadOnly"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>

          <v-divider />

          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isSaving"
              :loading="isSaving"
              color="primary"
              depressed
              @click="saveItem(false)"
              v-text="editItem.Id ? $t('Common.Button.Save') : $t('Common.Button.Create')"
            />

            <v-btn
              v-if="!isReadOnly"
              :disabled="isSaving"
              :loading="isSaving"
              color="green"
              dark
              depressed
              @click="saveItem(true)"
              v-text="editItem.Id ? $t('Common.Button.SaveAndSend') : $t('Common.Button.CreateAndSend')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Offers        from '../../api/mixins/Offers'
import OfferModel    from '../../api/mixins/models/OfferModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'OfferEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Offers, OfferModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Offers'
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch   : {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (!this.editItem.Id) this.editItem.InChargePerson = this.user.Fullname || ''
        })
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    saveItem (SendCustomerEmail) {
      this.formValidate()

      if (!this.formValidate()) return
      this.isSaving = true

      this.editItem.SendCustomerEmail = SendCustomerEmail
      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    addTyresProposal () {
      if (this.editItem.Tyres.length >= 4) return
      this.editItem.Tyres.push(JSON.parse(JSON.stringify(this.model.Tyres[0])))
    },

    removeTyresProposal (index) {
      if (this.editItem.Tyres.length === 1) return
      this.editItem.Tyres.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
