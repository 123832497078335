import BaseValidator from '../lib/data/Validations/BaseValidator'

export default {
  name: 'dialogData',
  data () {
    return {
      isSaving           : false,
      formValid          : false,
      dialog_data        : null,
      validator          : null,
      showGenericError   : false,
      serverErrorMessages: {},
      model              : {}
    }
  },
  props: {
    item: {
      type   : Object,
      default: undefined
    }
  },
  watch: {
    isVisible (newVal) {
      this.editItem = null

      if (newVal) {
        this.validator = new BaseValidator()
        this.$nextTick(() => {
          if (this.$refs.form) this.$refs.form.reset()
          this.editItem = JSON.parse(JSON.stringify(this.item || this.model || {}))
          this.validator = new BaseValidator(this.editItem, this.validationRules, this.validationErrorMessages)
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.form) this.$refs.form.resetValidation()
          if (this.$refs.formContainer) this.$refs.formContainer.scrollTop = 0
        })
      }
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
    }
  },
  methods: {
    formValidate () {
      this.formValid = true
      if (this.$refs.form) this.formValid = this.formValid && this.$refs.form.validate()
      if (this.validator) this.formValid = this.formValid && this.validator.validate()
      // console.log(this.validator.validationMessages)
      return this.formValid
    },

    handleFormSaveResponse (response) {
      if (response.data.status === 'success') {
        if (response.data.data === true) {
          this.formSaveSuccess()
        } else {
          this.formSaveError(response.data)
          this.showGenericError = true
        }
      } else {
        this.setServerErrorMessages(response.data.data)
      }
    },

    formSaveSuccess () {
      if (typeof this.closeDialog === 'function') this.closeDialog()
      this.showGenericError = false
      this.$emit('saveSuccess')
    },

    formSaveError (error) {
      this.$emit('saveError', error)
    },

    setServerErrorMessages (messages) {
      let key
      for (key in messages) {
        if (messages.hasOwnProperty(key)) this.serverErrorMessages[key] = messages[key]
      }
      this.formSaveError(messages)
    },
    clearServerErrorMessages () {
      this.showGenericError = false
      let key
      const tmpMessages = {}
      for (key in this.serverErrorMessages) {
        if (this.serverErrorMessages.hasOwnProperty(key)) tmpMessages[key] = []
      }
      this.serverErrorMessages = JSON.parse(JSON.stringify(tmpMessages))
    }
  },
  computed: {
    editItem: {
      get () {
        return this.dialog_data
      },
      set (val) {
        this.dialog_data = val
      }
    }
  }
}
