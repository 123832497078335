export default {
  name: 'DealerModel',

  data () {
    return {
      model: {
        Id        : null,
        Title     : '',
        CustomerId: '',
        Email     : '',
        AreaId    : null,
        AreaName  : '',
        Address   : '',
        PostCode  : '',
        Phone     : '',
        Website   : '',
        GroupId   : 1,
        Users     : [],
        UsersCount: 0,
        Active    : true
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        Title: {
          required: true,
          maxLen  : 255,
          type    : 'string'
        },
        CustomerId: {
          required: false,
          maxLen  : 20,
          type    : 'string'
        },
        Email: {
          required: false,
          maxLen  : 96,
          type    : 'email'
        },
        AreaId: {
          required: true,
          type    : 'integer'
        },
        PostCode: {
          required: true,
          length  : 5,
          type    : 'string'
        },
        Address: {
          required: true,
          maxLen  : 512,
          type    : 'string'
        },
        Phone: {
          required: true,
          length  : 10,
          type    : 'string'
        },
        Website: {
          required: false,
          type    : 'url'
        },
        GroupId: {
          required: true,
          type    : 'integer'
        },
        Users: {
          required: false,
          type    : 'array'
        },
        Active: {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
