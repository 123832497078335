<template>
  <v-container
    v-if="visible"
    class="fill-height"
    fluid
    @keydown.enter="authenticate"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        lg="4"
        md="5"
        sm="8"
        xl="3"
      >
        <v-card
          class="elevation-12"
          dark
        >
          <v-toolbar
            color="black"
            dark
            flat
          >
            <template #extension>
              <v-toolbar-title
                class="text-center mt-2"
                style="width: 100%;"
                v-text="$t('Auth.Login.Title')"
              />
            </template>
            <template #img>
              <v-img
                class="pa-0"
                contain
                height="75%"
                position="center top"
                src="/img/driver-center-logo.png"
              />
            </template>
          </v-toolbar>

          <v-card-text>
            <v-form
              ref="loginForm"
              v-model="form.valid"
            >

              <v-alert
                v-if="isDevelopment || isStaging"
                border="left"
                dense
                text
                type="error"
              >
                {{ isStaging ? $t('Common.Error.Staging') : $t('Common.Error.Development') }}
              </v-alert>

              <v-text-field
                v-model="username"
                :disabled="isAuthLoading"
                :label="$t('Auth.Login.Username')"
                :rules="form.rules.username"
                autocomplete="new-username"
                name="username"
                prepend-icon="mdi-account-circle"
                type="text"
              />

              <v-text-field
                v-model="password"
                :disabled="isAuthLoading"
                :label="$t('Auth.Login.Password')"
                :rules="form.rules.password"
                autocomplete="new-password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
              />
            </v-form>
            <v-alert
              v-model="authError"
              dense
              outlined
              type="error"
            >
              {{ authErrorMessage }}
            </v-alert>
          </v-card-text>
          <v-card-actions
            class="justify-center"
          >
            <v-btn
              id="btnLogin"
              :disabled="!form.valid || isAuthLoading"
              :loading="isAuthLoading"
              class="px-12"
              color="pred"
              dark
              @click="authenticate"
              v-text="$t('Common.Button.Login')"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth     from '../mixins/auth'
import UsersAPI from '../api/mixins/Users'

export default {
  name      : 'Login',
  components: {},
  directives: {},
  mixins    : [Auth, UsersAPI],
  props     : [],
  data () {
    return {
      visible : true,
      userid  : '',
      username: '',
      password: '',
      users   : [

      ],
      form: {
        valid: true,
        error: '',
        rules: {
          username: [
            (v) => !!v || this.$t('Auth.Login.Error.Username.Empty'),
            (v) => v && v.length >= 3 || this.$t('Auth.Login.Error.Username.Length', [3])
          ],
          password: [
            (v) => !!v || this.$t('Auth.Login.Error.Password.Empty'),
            (v) => v && v.length >= 6 || this.$t('Auth.Login.Error.Password.Length', [6])
          ]
        }
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.user = null
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () { },
  destroyed () {},
  methods: {
    authenticate () {
      if (this.$refs.loginForm.validate()) {
        this.login(this.username, this.password)
      }
    },

    onUserSelected () {
      this.password = this.users.find(u => u.username === this.username) ? this.users.find(u => u.username === this.username).password : ''
      this.userid = this.users.find(u => u.username === this.username) ? this.users.find(u => u.username === this.username).userid : ''
    }
  }
}
</script>

<style scoped>

</style>
