import axios               from 'axios'
import { AES, HmacMD5 }    from '@/lib/crypto/crypto'
import { i18n, languages } from '@/lang/lang'

const authorizationTokenKey = HmacMD5.hash('authorizationToken')
const errorCodes = [401, 403]

export const API = axios.create({
  baseURL        : process.env.VUE_APP_API_URL || '',
  timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 300000,
  responseType   : 'json',
  crossDomain    : true,
  withCredentials: true,
  headers        : {
    'X-Requested-With': 'XMLHttpRequest',
    'X-AppVersion'    : process.env.VUE_APP_VERSION || ''
  }
})

// Add auth token to every request with request interceptor
API.interceptors.request.use((request) => {
  const token = `${AES.decrypt(window.Vue.$sessionStorage.get(authorizationTokenKey, null))}` || null
  if (token !== null) request.headers.Authorization = `Bearer ${token}`
  request.headers['Accept-Language'] = languages.find(l => l.locale === i18n.locale).code

  return request
}, function (error) {
  return Promise.reject(error)
})

API.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error && error.message === 'Network Error' || errorCodes.includes(error.response.status)) {
    window.Vue.user = null
    if (window.Vue.$router.currentRoute.name !== 'Login') {
      window.Vue.$router.push('Login')
    }
  }
  return Promise.reject(error)
})

export const APICall = {
  Auth: {
    Login: {
      Endpoint: 'login',
      Type    : 'post'
    },
    Logout: {
      Endpoint: 'logout',
      Type    : 'post'
    }
  },

  Dashboard: {
    Get: {
      Endpoint: 'dashboard',
      Type    : 'get'
    }
  },

  Dealer: {
    List: {
      Endpoint: 'dealers',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'dealer/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'dealer',
      Type    : 'post'
    },
    Filters: {
      Endpoint: 'dealersFilter',
      Type    : 'post'
    },
    Areas: {
      Endpoint: 'areasFilter',
      Type    : 'post'
    },

    Group: {
      List: {
        Endpoint: 'dealerGroups',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'dealerGroup/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'dealerGroup',
        Type    : 'post'
      }
    }
  },

  Offer: {
    List: {
      Endpoint: 'offers',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'offer/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'offer',
      Type    : 'post'
    },
    Delete: {
      Endpoint: 'offer/{id}',
      Type    : 'delete'
    }
  },

  Order: {
    List: {
      Endpoint: 'orders',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'order/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'order',
      Type    : 'post'
    },
    Delete: {
      Endpoint: 'order/{id}',
      Type    : 'delete'
    }
  },

  Review: {
    List: {
      Endpoint: 'reviews',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'review/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'review',
      Type    : 'post'
    }
  },

  User: {
    List: {
      Endpoint: 'users',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'user/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'user',
      Type    : 'post'
    }
  },

  Area: {
    List: {
      Endpoint: 'areas',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'area/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'areas',
      Type    : 'post'
    }
  },

  Setting: {
    List: {
      Endpoint: 'settings',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'setting/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'setting',
      Type    : 'post'
    }
  }
}
