import Vue from 'vue'

const DataStore = Vue.observable({

  App: {
    version: process.env.VUE_APP_VERSION
  },
  Config   : {},
  User     : null,
  Dashboard: null
})

export default DataStore
